import moment from "moment";

let timesArray = []; // {label: "3:15 AM", value: "03:15"}[]
const interval = 15;
for (var tt = 0; tt < 24 * 60; tt += interval) {
    let ampm = ' AM';
    let hh = Math.floor(tt / 60);
    const realHH = Math.floor(tt / 60);
    if (hh === 0) hh = 12;
    if (timesArray.length > (12 * (60 / interval) - 1)) {
        ampm = ' PM';
        if (hh > 12) hh -= 12;
    }
    const mm = (tt % 60);
    timesArray.push({
        label: hh + ':' + ("0" + mm).slice(-2) + ampm,
        value: ("0" + realHH).slice(-2) + ':' + ("0" + mm).slice(-2)
    });
}

const NEW_VISIT_DEFAULT_FIRST_SHIFT = {
	startTime: moment({ hours: 9, minutes: 0}),
	endTime: moment({ hours: 13, minutes: 0}),
    isOverNight: false,
    duration: "4H",
	durationHours: 4,
	durationMinutes: 0
};

const NEW_VISIT_DEFAULT_SECOND_SHIFT = {
	startTime: moment({ hours: 14, minutes: 0}),
	endTime: moment({ hours: 18, minutes: 0}),
    isOverNight: false,
    duration: "4H",
	durationHours: 4,
	durationMinutes: 0
};

const advnacedDays = [
    { name: 'Mon', shortName: "Mo", fullName: 'Monday' },
    { name: 'Tues', shortName: "Tu", fullName: 'Tuesday' },
    { name: 'Wed', shortName: "We", fullName: 'Wednesday' },
    { name: 'Thur', shortName: "Th", fullName: 'Thursday' },
    { name: 'Fri', shortName: "Fr", fullName: 'Friday' },
    { name: 'Sat', shortName: "Sa", fullName: 'Saturday' },
    { name: 'Sun', shortName: "Su", fullName: 'Sunday' },
];

const languages = [
    // main langs
    "English",
    "Spanish",
    "Russian",
    "Mandarin",
    "French",
    "Arabic",
    // extra langs
    "Albanian",
    "Armenian",
    "Bengali",
    "Bulgarian",
    "Cantonese",
    "Chinese",
    "Creole",
    "Danish",
    "Dutch",
    "Estonian",
    "Farsi",
    "Filipino",
    "Fukkianese",
    "Fula/Fulani",
    "Fuzhounese",
    "Georgian",
    "German",
    "Ghana",
    "Greek",
    "Hakka",
    "Hebrew",
    "Hindi",
    "Hungarian",
    "Italian",
    "Japanese",
    "Korean",
    "Krio",
    "Kyrgyz",
    "Pashto",
    "Persian",
    "Polish",
    "Portuguese",
    "Punjabi",
    "Romanian",
    "Shanghainese",
    "Sign",
    "Soninke",
    "Swahili",
    "Tagalog",
    "Taishanese",
    "Taiwanese",
    "Turkish",
    "Twi",
    "Ukrainian",
    "Urdu",
    "Uzbek",
    "Vietnamese",
    "Yiddish"
];

const visitListStatusTypesMapping = {
    ASSIGNED: {
        text: "Assigned Visit",
        color: "#fff",
        backgroundColor: "#8CCC85"
    },
    UNSTAFFED: {
        text: "Unstaffed",
        color: "#fff",
        backgroundColor: "#FF8360"
    },
    MISSED: {
        text: "Missed",
        color: "#fff",
        backgroundColor: "#E66057"
    },
    REMOVED: {
        text: "Removed",
        color: "#fff",
        backgroundColor: "#E66057"
    },
    NO_AUTHORIZATION: {
        text: "No Authorization",
        color: "#fff",
        backgroundColor: "#E66057"
    },
    PTO: {
        text: "Paid time off",
        color: "#fff",
        backgroundColor: "#968CEC"
    },
    BROADCAST: {
        text: "Broadcasted",
        color: "#fff",
        backgroundColor: "#FCA85B"
    },
    PENDING_REQUESTS: {
        text: "Pending Requests",
        color: "#3391FE",
        backgroundColor: "#EBF4FF"
    },
    AWAITING_ASSIGNMENT: {
        text: "Awaiting Assignment",
        color: "#fff",
        backgroundColor: "#ff9800"
    },
    PATIENT_VACATION: {
        text: "ON VACATION",
        color: "#b700c7",
        backgroundColor: "#fbcdff"
    },
    CAREGIVER_ABSENCE: {
        text: "In Absence",
        color: "#b700c7",
        backgroundColor: "#fbcdff"
    }
};

const allValidatorIds = [
    "no_matching_authorization",
    "authorization_over_allocation",
    "missing_authorization_hours",
    "schedule_auth_allocation_conflict",
    "invalid_authorization_assignment",
    "missing_clock_times",
    "no_caregiver",
    "clockin_time_mismatch",
    "clockout_time_mismatch",
    "no_billing_rate",
    "no_billing_rate_county",
    // "zero_billing_rate",
    "same_day_visits_with_issues",
    // "caregiver_compliance",
    "has_patient_overlap_issue",
    "has_caregiver_overlap_issue",
    "missing_dx_codes",
    "daily_minimum_hours_not_met",
    "overbilled_day",
    "missed_visit_no_travel_time",
    "missing_matching_mutual_visit",
    "zero_billing_units",
    "unapproved_manual_clock_time_edit",
    "caregiver_incompliant",
    "insufficient_visit_duties",
    "missing_personal_care_duty",
    "excessive_clock_distance",
    "manual_hold_invoicing",
    "zero_dollar_pay_rate",
    "missing_caregiver_ssn",
    "missing_patient_member_id",
    "missing_address"
];
    
const staffingIssuesValidatorIds = [
    { template: "CAREGIVERS", id: "CAREGIVER_NOT_ACTIVE_ON_A_CASE", name: "Caregiver not active on a case" },
    { template: "CAREGIVERS", id: "CAREGIVERS_ON_VISIT_WITH_OVERTIME", name: "Caregiver on a visit, but with overtime" },
    { template: "PATIENTS", id: "ACTIVE_PATIENTS_WITH_NO_AUTHORIZATION", name: "Active patient with no authorization" },
    { template: "PATIENTS", id: "ACTIVE_PATIENT_WITH_NO_CONTRACT", name: "Active patient with no contract (exclude General contract)" },
    { template: "PATIENTS", id: "PATIENTS_WITH_VISITS_WITH_NO_PLAN_OF_CARE", name: "Patient with visits with no Plan of care" },
    { template: "CAREGIVERS", id: "CAREGIVER_ON_VISIT_PATIENT_NOT_ACTIVE", name: "Caregiver on visit patient not active" },
    { template: "PATIENTS", id: "PATIENTS_WITHOUT_WEEKLY_TEMPLATES", name: "Patient without weekly templates" },
    { template: "PATIENTS", id: "PATIENT_WITH_NO_POC", name: "Patient with no Plan of Care" },
    { template: "PATIENTS", id: "PATIENT_WITH_AUTHORIZATION_MISSING_VISITS", name: "Patient with under allocated visits"},
    { template: "PATIENTS", id: "PATIENT_WITH_AUTHORIZATION_MISSING_ALL_VISITS", name: "Patient with authorization but missing visits during authorization period"},
    { template: "PATIENTS", id: "PATIENT_WITH_UNASSIGED_VISITS", name: "Patient with unassigned visits"},
    { template: "PATIENTS", id: "PATIENT_WITH_WEEKLY_TEMPLATE_MISSING_CAREGIVER", name: "Patient with weekly template but missing caregiver"},
    { template: "CAREGIVERS", id: "CAREGIVER_ASSIGNED_TO_VISITS_BUT_HAS_ABSENCE", name: "Visit scheduled but the caregiver is in absence" },
];

const visitInfoOptions = [
    "Live in", 
    "Pediatric", 
    "Geriatric", 
    "Autism", 
    "Weekend", 
    "Mid-week", 
    "Overnight"
]

export const visitConsts = {
    times: timesArray,
    defaultFirstShift: NEW_VISIT_DEFAULT_FIRST_SHIFT,
    defaultSecondShift: NEW_VISIT_DEFAULT_SECOND_SHIFT,
    advancedDays: advnacedDays,
    languages: languages,
    visitListStatusTypesMapping: visitListStatusTypesMapping,
    allValidatorIds,
    staffingIssuesValidatorIds,
    visitInfoOptions
};

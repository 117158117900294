import { useCallback, useEffect, useRef, useState } from "react";

const NaNToDefault = (val: number, def: number): number => {
  return isNaN(val) ? def : val;
};

const useAudio = (src: string) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [currentTimeSeconds, setTimeSeconds] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [durationSeconds, setDurationSeconds] = useState(0);
  const audio = useRef(new Audio(src));

  const updateData = useCallback((canPlay?: boolean) => {
    if (canPlay !== undefined) {
      setIsLoaded(canPlay);
    }
    setIsPlaying(!audio.current.paused);
    setDurationSeconds(NaNToDefault(audio.current.duration, 0));
    setTimeSeconds(audio.current.currentTime);
    setPlaybackRate(audio.current.playbackRate);
  }, []);

  useEffect(() => {
    const currAudio = audio;
    const interval = setInterval(updateData, 500);
    return () => {
      clearInterval(interval);
      currAudio.current.pause();
    };
  }, [updateData]);

  audio.current.addEventListener("canplaythrough", () => updateData(true));
  audio.current.addEventListener("ended", () => {
    audio.current.pause();
    audio.current.currentTime = 0;
    updateData();
  });

  const play = async () => {
    await audio.current.play();
    setIsPlaying(true);
  };
  const pause = () => {
    audio.current.pause();
    setIsPlaying(false);
  };
  const updatePlaybackRate = (val: number) => {
    audio.current.playbackRate = val;
    setPlaybackRate(val);
  };
  const setPosition = (val: number) => {
    audio.current.currentTime = val;
    setTimeSeconds(val);
  };
  return {
    isPlaying,
    isLoaded,
    currentTimeSeconds,
    durationSeconds,
    playbackRate,
    play,
    pause,
    setPlaybackRate: updatePlaybackRate,
    setPosition,
  };
};

export default useAudio;

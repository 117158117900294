export const patientSurplusChecksList = {
  templateUrl: "admin/views/patient-surplus-checks.html",
  bindings: {
    // items: "<",
    patientId: "<",
  },
  //! @ngInject
  controller: function (
    $scope,
    $rootScope,
    DatabaseApi,
    patientService,
    NgTableParams,
    toaster,
    $uibModal,
    SweetAlert
  ) {
    // $scope.patientSurpluses = []
    const agencyMembers = DatabaseApi.getAgencyMembersArr();
    const initPatientSurplusChecksTable = () => {
      const options = {
        count: 20,
        sorting: { id: "desc" },
      };

      $scope.patientSurplusChecksTable= this.patientSurplusChecksTable = new NgTableParams(options, {
        dataset:  $scope.patientSurplusChecks,
        counts: [],
                
      });
    };
    const initialize = async () => {
      $scope.patientId = this.patientId;
      $scope.patientSurplusChecks = this.patientSurplusChecks = (await patientService.getPatientSurplusChecks(
        this.patientId
      )).patientSurplusChecks.map((i)=>
       {
        const agencyMember = agencyMembers.find(a => a.id === i.createdBy);
        if (agencyMember) {
          i.createdByName = agencyMember.displayName;
      }
        return i
      })
      
      initPatientSurplusChecksTable();
      console.log('$scope.patientSurplusChecks',$scope.patientSurplusChecks);
    };

    $scope.openNewPatientSurplusCheckModal = () => {
      var modalInstance = $uibModal.open({
        templateUrl: "admin/views/patient-surplus-check-create-modal.html",
        
        controller: "newPatientSurplusCheckModalCtrl",
        resolve: {
          patientId: function () { return $scope.patientId },
        }
      });

      modalInstance.result.then(
        function (data) {
          
          initialize()
          $rootScope.$broadcast('got_surplus_checks_data');
        },
        function () {
          toaster.pop("error", "Something went wrong");
        }
      );
    };

    this.$onInit = () => {
      initialize();
    };
  },
};

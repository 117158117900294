import angular from "angular";
import _ from "lodash";
import { CaregiverId } from "../../../../scripts/messages/ids";
import { BankAccountInformationForm, CaregiverDirectDepositInfoBindings } from "./caregiver-direct-deposit-info.component.types";
import { BankAccountInformationRow, BankAccountTypeRow, BankRow } from "./caregiver-direct-deposit-info.component.types"
import "./caregiver-direct-deposit-info.component.scss"
import { CaregiverService } from "../../services/caregiver.service";

//! @ngInject
class CaregiverDirectDepositInfoCtrl implements angular.IComponentController, CaregiverDirectDepositInfoBindings {
    caregiverId!: CaregiverId

    banks: BankRow[] = [];
    bankAccountTypes: BankAccountTypeRow[] = [];
    bankAccountInformation: BankAccountInformationRow | null = null;
    
    otherBankName: BankRow | undefined = undefined;

    form: BankAccountInformationForm = {};

    isLoading: boolean;
    isInEditMode: boolean;

    constructor(
        private toaster: toaster.IToasterService,
        private $rootScope: ng.IRootScopeService,
        private $timeout: ng.ITimeoutService,
        private caregiverService: CaregiverService
    ) {
        this.isLoading = false;
        this.isInEditMode = false;
    }

    $onInit = () => {
        this.loadAllBankData()
    };

    loadAllBankData = () => {
        this.isLoading = true;
        Promise.all([
            this.getBanks(),
            this.getBankAccountTypes(),
            this.getBankAccountInformation()
        ]).then(() => {
            this.updateForm()
        }).finally(() => {
            this.$timeout(() => this.isLoading = false)
        })
    }

    updateForm = () => {
        const selectedBankAccountType = {...this.bankAccountTypes.find((type) => type.id == this.bankAccountInformation?.bankAccountTypeId)}
        this.form.bankAccountType = selectedBankAccountType

        const selectedBank = {...this.banks.find((bank) => bank.id == this.bankAccountInformation?.bankId)}
        this.form.bank = selectedBank

        this.form.accountNumber = this.bankAccountInformation?.accountNumber
        this.form.routingNumber = this.bankAccountInformation?.routingNumber
        this.form.hasAgreedToTerms = !!this.bankAccountInformation?.hasAgreedToTerms
    }

    getBanks = () => {      
        return this.caregiverService.getBanks()
            .then((banks) => {
                this.banks = this.formatBanksForMultiselect(banks)
            }).catch((e) => {
                this.toaster.pop("error", "Failed to load banks", e);
            })
    }

    getBankAccountTypes = () => {
        return this.caregiverService.getBankAccountTypes()
            .then((accountTypes) => {
                this.bankAccountTypes = this.formatAccountTypesForMultiselect(accountTypes)
            }).catch((e) => {
                this.toaster.pop("error", "Failed to load banks", e);
            })
    }

    getBankAccountInformation = () => {
        return this.caregiverService.getBankAccountInformation(this.caregiverId)
            .then((bankAccountInformation) => {
                this.bankAccountInformation = bankAccountInformation
            }).catch((e) => {
                this.toaster.pop("error", "Failed to load bank information data", e);
            })
    }

    updateBankAccountInformation = () => {
        if (this.areAnyBankAccountFieldsNil()) {
            this.toaster.pop("error", "All bank account fields must be filled")
            return;
        }

        const body = {
            accountNumber: this.form.accountNumber,
            routingNumber: this.form.routingNumber,
            bankAccountTypeId: this.form.bankAccountType?.id,
            bankId: this.form.bank?.id,
            hasAgreedToTerms: this.form.hasAgreedToTerms
        }

        this.caregiverService.updateBankAccountInformation(this.caregiverId, body)
            .then(() => {
                this.getBankAccountInformation().then(() => {
                    this.updateForm()
                })
                this.toaster.pop("success", "Successfuly updated bank account information")
            }).catch((err) => {
                this.toaster.pop("error", "Couldn't save bank account information", err)
            })
    }

    areAnyBankAccountFieldsNil = () => {
        return Object.values(this.form).some(x => _.isNil(x) || x === "")
    }

    formatBankAccountInformation = (bankAccountInfo) => {
        return {
            ...bankAccountInfo,
            accountType: bankAccountInfo.bankAccountTypeId
        }
    }

    formatBanksForMultiselect = (banks) => {
        return banks.map((bank) => ({
            id: bank.id,
            label: bank.name
        }))
    }

    formatAccountTypesForMultiselect = (accountTypes) => {
        return accountTypes.map((accountType) => ({
            id: accountType.id,
            label: accountType.type
        }))
    }

    selectBankAccountTypeExtraSettings = {
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
    }

    selectBankExtraSettings = {
        ...this.selectBankAccountTypeExtraSettings,
        enableSearch: true,
        scrollable: true,
        scrollableHeight: '200px',
    }
}

interface CaregiverDirectDepositInfoComponentOptions extends angular.IComponentOptions {
    $name: string;
}

export const CaregiverDirectDepositInfoComponent: CaregiverDirectDepositInfoComponentOptions = {
  $name: "caregiverDirectDepositInfoComponent",
  controller: CaregiverDirectDepositInfoCtrl,
  controllerAs: "ctrl",
  templateUrl: "admin/modules/caregiver/components/caregiver-direct-deposit-info/caregiver-direct-deposit-info.component.html",
  bindings: {
    caregiverId: "<",
  },
};

import { webappChannel } from "@medflyt/webapp-react/src/core";
import { AngularJSPayload, EventFromReact } from "@medflyt/webapp-react/src/core/webapp-channel";
import angular from "angular";
import { angularize, getService } from "react-in-angularjs";
import CommCenterChatWrapper from "@medflyt/webapp-react/src/modules/communication/components/CommCenterChatWrapper";
import DocumentViewer from "@medflyt/webapp-react/src/modules/compliance/components/DocumentViewer";
import PhoneNumberDialer from "@medflyt/webapp-react/src/shared/components/PhoneNumberDialer";
import { withApp, withBasicApp } from "@medflyt/webapp-react/src/utils";
import { DatabaseApiService } from "public/admin/scripts/services/db";

export function registerReactAdapterModule() {
  registerReactAdapterService();

  angularize(
    withApp(CommCenterChatWrapper),
    "reactCommCenterChatWrapper",
    angular.module("dashboard"),
    {
      patientId: "<",
      caregiverId: "<",
      notIdentifiedPhoneNumber: "<",
      agencyMemberId: "<",
      defaultTicketId: "<",
      onClose: "&",
      primaryEntity: "<",
      defaultMessage: "<",
      defaultLabel: "<",
    }
  );

  angularize(withBasicApp(DocumentViewer), "canvasViewer", angular.module("dashboard"), {
    src: "<",
  });

  angularize(withApp(PhoneNumberDialer), "phoneNumberDialer", angular.module("dashboard"), {
    /**
     * Optional
     */
    predefinedPhoneNumber: "<",
    onClose: "&",
  });

  setTimeout(initWebappChannelPayload);
}

function registerReactAdapterService() {
  //! @ngInject
  class ReactAdapterService {
    constructor(
      public $rootScope: angular.IRootScopeService,
      public Storage: any,
      public $state: ng.ui.IStateService,
      public $timeout: ng.ITimeoutService,
      public DatabaseApi: DatabaseApiService
    ) {
      this.listenForReactEvents();
    }

    listenForReactEvents() {
      window.addEventListener("from-webapp-react", ($event) => {
        const event = ($event as CustomEvent).detail as EventFromReact;
        if (event.type === "navigate") {
          switch (event.payload.entity) {
            case "Caregiver":
              return this.$rootScope.openCaregiverModal(event.payload.id as any);
            case "Patient":
              return this.$rootScope.openPatientModal(event.payload.id as any);
            case "VisitInstance":
              return this.$rootScope.openVisitInstanceModal(event.payload.id as any);
            case "VisitBroadcast":
              return this.$rootScope.openVisitBroadcastModalById(event.payload.id as any);
            case "Document":
              return this.$rootScope.openDocumentPreviewModalById(event.payload.id as any);
            case "Note":
              return this.$rootScope.openNewPatientIntakeNoteModal(
                event.payload.id as any,
                event.payload.callback
              );
          }
        } else if (event.type === "setActiveCall") {
          console.log("updating active call on root scope: ", event.payload);
          this.$timeout(() => (this.$rootScope.activeCall = event.payload));
          return;
        }
      });
    }

    getAuthInfo(): AngularJSPayload["AUTH_SET"] {
      const authFromStorage = this.Storage.getObject("user");

      if (Object.keys(authFromStorage).length === 0) {
        return null;
      }

      return {
        authToken: authFromStorage.authToken,
        refreshToken: authFromStorage.refreshToken,
      };
    }

    init() {
      webappChannel.setPayload("AUTH_SET", this.getAuthInfo());
      this.$rootScope.$watch("activeCall", () =>
        webappChannel.setPayload("ACTIVE_CALL_SET", this.$rootScope.activeCall)
      );
    }
  }

  angular.module("dashboard").service("reactAdapterService", ReactAdapterService);
}

export function initWebappChannelPayload() {
  const service = getService("reactAdapterService");

  service.init();
}

export function dispatchSocketMessageToWebappChannel(event: string, payload: string) {
  webappChannel.dispatch("SOCKET_EVENT_RECEIVED", {
    type: event as any,
    payload: safeJsonParse(payload),
  });
}

function safeJsonParse(json: string) {
  try {
    return JSON.parse(json);
  } catch (e) {
    return null;
  }
}

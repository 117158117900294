export function isDefined<T>(value: T | undefined): value is NonNullable<T> {
  return value !== undefined && value !== null;
}

export function assertDefined<T>(value: T | null | undefined, description: string): T {
  if (isDefined(value)) {
    return value;
  }

  throw new Error(`Expected defined value for "${description}", received ${value} instead.`);
}

export function fmap<S, T>(val: S | null, f: (x: S) => T): T | null {
  if (val === null) {
    return null;
  } else {
    return f(val);
  }
}

export function groupBy<T extends object, K extends keyof T>(collection: T[], iteratee: K) {
  const map: Map<T[K], T[]> = new Map();

  for (const item of collection) {
    const accumalated = map.get(item[iteratee]);
    if (accumalated === undefined) {
      map.set(item[iteratee], [item]);
    } else {
      map.set(item[iteratee], [...accumalated, item]);
    }
  }

  return map;
}

export function upperCaseFirst(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

export function arrayAt<T>(arr: T[], idx: number): T | undefined {
  return arr[idx];
}

export function chainBooleanFn<T>(fn1: (...vals: T[]) => boolean, fn2: (...val: T[]) => boolean) {
  return (...val: T[]) => fn1(...val) && fn2(...val);
}

export function chain<T, D, A>(fn1: (val: T) => D, fn2: (val: D) => A) {
  return (val: T) => fn2(fn1(val));
}
function swap<T>(array: T[], pos1: number, pos2: number) {
  const temp = array[pos1];
  array[pos1] = array[pos2];
  array[pos2] = temp;
}

export function heapsPermute<T>(array: T[], n: number = array.length, results: T[][] = []) {
  const length = n || array.length;
  if (length === 1) {
    results.push(array.slice());
  } else {
    for (let i = 1; i <= length; i += 1) {
      heapsPermute(array, length - 1, results);
      const j = (() => {
        if (length % 2 > 0) {
          return 1;
        } else {
          return i;
        }
      })();
      swap(array, j - 1, length - 1);
    }
  }
  return results;
}

import { Moment } from "moment";
import { FaxDetailsId } from "webapp-react/src/shared/schema/schema";
import { CaregiverId, PatientId } from "../../scripts/messages/ids";
import { Api } from "../../scripts/services/Api";
import { Endpoint } from "../../scripts/services/endpoint.service";
import { GetFaxesResponse } from "./faxes.types";

//! @ngInject
export class FaxesService {
  constructor(
    private api: Api,
    private endpoint: Endpoint,
    private $rootScope: ng.IRootScopeService
  ) {}

  getFaxes = (
    caregiverId?: CaregiverId,
    patientId?: PatientId,
    createdFrom?: Moment,
    createdTo?: Moment,
    status?: string[]
  ) => {
    if (patientId === undefined && caregiverId === undefined) {
      throw new Error("Should have at least one of: caregiverId, patientId");
    }

    let path = "agencies/:agencyId/agency_members/:agencyMemberId/faxes?";
    const queryParams: string[] = []; 

    if(caregiverId !== undefined){
      queryParams.push(`caregiver=${CaregiverId.unwrap(caregiverId).toString()}`);
    }

    if(patientId !== undefined){
      queryParams.push(`patient=${PatientId.unwrap(patientId).toString()}`);
    }
    
    if(createdFrom !== undefined){
      queryParams.push(`createdAtFrom=${createdFrom?.format("YYYY-MM-DD")}`);
    }

    if(createdTo !== undefined){
      queryParams.push(`createdAtTo=${createdTo?.format("YYYY-MM-DD")}`);
    }

    if(status !== undefined){
      for(const s of status){
        queryParams.push(`faxStatus[]=${s}`);
      }
    }

    path += queryParams.join("&").toString();

    const url = this.endpoint({
      path,
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.get<GetFaxesResponse>(url);
  };

  markFaxAsDone = (faxId: FaxDetailsId) => {
    const url = this.endpoint({
      path: "agencies/:agencyId/agency_members/:agencyMemberId/faxes/update_status",
      params: {
        agencyId: this.$rootScope.agencyId,
        agencyMemberId: this.$rootScope.agencyMemberId,
      },
    });

    return this.api.post(url, {
      id: faxId,
    });
  };
}

import angular from "angular";
import { caregiverChatButtonComponent } from "./components/caregiver-chat-button/caregiver-chat-button.component";
import { countdownTimerComponent } from "./components/countdown-timer/countdown-timer.component";
import { datePickerComponent } from "./components/date-picker/date-picker.component";
import { editableLabelComponent } from "./components/editable-label/editable-label.component";
import { imageCanvasViewerComponent } from "./components/image-canvas-viewer/image-canvas-viewer.component";
import { multiselectComponent } from "./components/multiselect/multiselect.component";
import { pieChartComponent } from "./components/pie-chart/pie-chart.component";
import { progressBarComponent } from "./components/progress-bar/progress-bar.component";
import { selectAllCheckboxComponent } from "./components/select-all-checkbox/select-all-checkbox.component";
import sanitizeFilter from "./filters/sanitize.filter";

export function registerSharedModule() {
  return (
    angular
      .module("dashboard")

      // Filters
      .filter(sanitizeFilter.$name, sanitizeFilter)

      // Components
      .component(pieChartComponent.$name, pieChartComponent)
      .component(multiselectComponent.$name, multiselectComponent)
      .component(selectAllCheckboxComponent.$name, selectAllCheckboxComponent)
      .component(progressBarComponent.$name, progressBarComponent)
      .component(imageCanvasViewerComponent.$name, imageCanvasViewerComponent)
      .component(caregiverChatButtonComponent.$name, caregiverChatButtonComponent)
      .component(countdownTimerComponent.$name, countdownTimerComponent)
      .component(editableLabelComponent.$name, editableLabelComponent)
      .component(datePickerComponent.$name, datePickerComponent)
  );
}

import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { CallSessionId, CommCenterTicketId } from "../schema/schema";
import useActiveCall from "./useActiveCall";
import useApi from "./useApi";
import * as callCenterUtils from "../../shared/utils/call-center";
import useSocketEvent from "./useSocketEvent";
import { InboundCallSocketPayloadAdditionalData } from "../../../../public/admin/modules/call-center/components/call-center.types";
import { CallerCallee } from "../../core/webapp-channel";

const usePhoneNumberDialer = (onSuccessCall?: () => void) => {
  const { api } = useApi();
  const [isCalling, setIsCaling] = useState(false);
  const { activeCall, setActiveCall } = useActiveCall();
  const [newCallFromDial, setNewCallFromDial] = useState<{
    ticketId: CommCenterTicketId;
    sessionId: CallSessionId;
  } | null>(null);
  const [gotNewDialSocket, setGotNewDialSocket] = useState(false);
  const [phoneNumberDialed, setPhoneNumberDialed] = useState("");

  const notifyServer = useMutation({
    mutationKey: ["outbound_session_connected"],
    mutationFn: (callSessionId: CallSessionId) =>
      api
        .post("./call_center/:callSessionId/outbound_session_connected", {
          path: {
            callSessionId,
          },
        })
        .then(() => {
          if (activeCall !== null) {
            setActiveCall({ ...activeCall, status: "Active" });
          }
        }),
  });

  useEffect(() => {
    if (
      gotNewDialSocket &&
      newCallFromDial !== null &&
      !notifyServer.isLoading &&
      !notifyServer.isSuccess &&
      activeCall !== null
    ) {
      notifyServer.mutate(newCallFromDial.sessionId);
    }
  }, [activeCall, gotNewDialSocket, newCallFromDial, notifyServer]);

  useSocketEvent({
    key: "CallCenterOutboundCall",
    onEvent: (event) => {
      const additionalData: InboundCallSocketPayloadAdditionalData | CallerCallee.Caregiver =
        (() => {
          switch (event.callee.type) {
            case "AgencyMember":
            case "Caregiver":
            case "Patient":
              return {
                ...event.callee,
                source: event.callee.phoneNumber,
              };
            case "NotIdentifiedPhoneNumber":
              return {
                type: "NotIdentifiedPhoneNumber",
                notIdentifiedType: "NotIdentifiedPhoneNumber",
                source: phoneNumberDialed,
              };
          }
        })();
      setNewCallFromDial({
        sessionId: event.callSessionId,
        ticketId: event.ticketId,
      });
      setActiveCall({
        participantId: event.participantId,
        sessionToken: event.sessionToken,
        status: "Active",
        ticketId: event.ticketId,
        callSessionId: event.callSessionId,
        liveCallModalOpen: true,
        additionalData: additionalData,
      });
    },
  });

  const dialNumber = (E164Number: string) => {
    setIsCaling(true);
    setPhoneNumberDialed(E164Number);
    console.log(`Calling: ${E164Number}`);
    callCenterUtils.startOutboundCall(E164Number, {
      onSessionConnected: () => {
        setGotNewDialSocket(true);
        if (onSuccessCall !== undefined) {
          onSuccessCall();
        }
      },
      onSessionTerminated: () => {
        setIsCaling(false);
        console.log("session terminated");
        if (activeCall !== null) {
          setActiveCall({ ...activeCall, status: "Completed" });
        }
      },
    });
  };

  return { dialNumber, isCalling };
};

export default usePhoneNumberDialer;

//! @ngInject
export class NotificationsService {
  constructor(private $rootScope: ng.IRootScopeService) {}
  notify = (
    notificationTitle: string,
    params: { actionOnClick?: () => void; notificationBody?: string }
  ) => {
    const notificationBody = params?.notificationBody;
    const onClick = params?.actionOnClick;

    if ("Notification" in window) {
      switch (Notification.permission) {
        case "granted":
          new Notification(notificationTitle, {
            body: notificationBody,
            icon: "admin/images/favicon-96x96.png",
            tag: notificationTitle + (notificationBody ?? ""),
          }).onclick = onClick ?? null;
          break;
        case "default":
          Notification.requestPermission().then((permission) => {
            if (permission === "granted") {
              new Notification(notificationTitle, {
                body: notificationBody,
                icon: "admin/images/favicon-96x96.png",
                tag: notificationTitle + (notificationBody ?? ""),
              }).onclick = onClick ?? null;
            }
          });
          break;
        case "denied":
        default:
          break;
      }
    }
  };
}

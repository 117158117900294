//! @ngInject
export function billingExportedInvoicesModalCtrl(
    $rootScope,
    $scope,
    $state,
    $uibModalInstance,
    NgTableParams,
    visit,
    billingInvoicesProcessor,
    toaster,
    DatabaseApi
  ) {
    $scope.isFetching = false;
    $scope.tableParams = undefined;
    $scope.exportHistoryRows = [];

    $scope.navigateToExportBatch = exportBatchId => {
      // 1 - go to batch export details
      $state.go("app.billing.export", { export: { id: exportBatchId } });

      // 2 - dismiss modal
      $uibModalInstance.dismiss();

      // 3 - scroll top
      window.scrollTo(0, 0);
    };

    /**
     * Being fired on controller initiation
     */
    function initialize() {
      loadExportedInvoices();
    }

    /**
     * Load exported invoices by the given visit of the scope.
     */
    function loadExportedInvoices() {
      $scope.isFetching = true;

      return billingInvoicesProcessor
        .getExportingHistoryByVisitId(visit.visitInstanceId, {
          agencyId: $rootScope.agencyId,
          agencyMemberId: $rootScope.agencyMemberId
        })
        .then(onSuccessFetchRequest)
        .catch(onErrorFetchRequest)
        .finally(() => ($scope.isFetching = false));
    }

    /**
     * Handle a successful response of exported invoices
     * @param {Response} response
     */
    function onSuccessFetchRequest(response) {
      mapExportHistoryData(response.data);
      initTableParams();
    }

    /**
     * Handle a failure response of exported invoices
     * @param {Response} response
     */
    function onErrorFetchRequest(response) {
      console.error(response);

      toaster.pop(
        "error",
        "Error",
        "Failed fetch exported invoices for the current visit."
      );
    }

    function mapExportHistoryData(exportHistoryData) {
      const agencyMembers = DatabaseApi.getAgencyMembersArr();

      exportHistoryData.unexportedInvoices.forEach((unexportedInvoice) => {
          $scope.exportHistoryRows.push({
            type: "unexport",
            agencyMember: unexportedInvoice.unexportedBy,
            actionTime: unexportedInvoice.unexportedAt,
            hasBeenRejected: undefined
          })
      })

      exportHistoryData.exportedInvoices.forEach((exportedInvoice) => {
        $scope.exportHistoryRows.push({
          type: "export",
          exportBatchDisplayId: exportedInvoice.exportBatchDisplayId,
          exportBatchIsAdjustment: exportedInvoice.exportBatchIsAdjustment,
          agencyMember: exportedInvoice.exportVisitCreatedBy,
          actionTime: exportedInvoice.exportVisitCreatedAt,
          hasBeenRejected: exportedInvoice.hasBeenRejected,
          visitInstanceId: exportedInvoice.visitInstanceId,
          exportBatchId: exportedInvoice.exportBatchId       
        })
      })

      $scope.exportHistoryRows.forEach((row) => {
        const creaingAgencyMember = agencyMembers.find((member) => row.agencyMember === member.id)
    
        if(creaingAgencyMember){
            row.agencyMember = `${creaingAgencyMember.firstName} ${creaingAgencyMember.lastName}`
        }
      })
    }

    /**
     * Initialize table
     */
    function initTableParams() {
      const options = {
        count: $scope.exportHistoryRows.length
      };

      $scope.tableParams = new NgTableParams(options, {
        counts: [],
        dataset: $scope.exportHistoryRows
      });
    }

    $scope.closeModal = () => {
      $uibModalInstance.close('ok');
    };

    initialize();
  };

import { DateTimeFormatter, LocalDate } from "js-joda";
import { mapPlanOfCareItemIdsToFormDuties } from "./newPlanOfCareModalCtrl";

//! @ngInject
export function hhaIntegrationNonBillingCtrl(
    $scope,
    $rootScope,
    NgTableParams,
    hhaxIntegrationService,
    DatabaseApi,
    PatientModalService,
    selectionLogic,
    toaster,
    $uibModal,
    $filter
) {
    $scope.selectedOffices = [];
    $scope.selectedPayers = [];
    $scope.dateRecordTimes = false;
    $scope.offices = DatabaseApi.offices().map((office) => ({
        id: office.id,
        label: office.name,
    }));

    $scope.payers = DatabaseApi.activePayers().
        filter(a => DatabaseApi.contractTypes().
        filter(b => b.eBillingProvider === 'HHAExchange').
        map(c => c.payer).includes(a.id)).map((payer) => ({
            id: payer.id,
            label: payer.name,
        }));
    $scope.recordStatuses = [{
        id: "NEW",
        label: "New"
    },
    {
        id: "IGNORED",
        label: "Ignored"
    },
    {
        id: "PROCESSED",
        label: "Processed"
    }];
    $scope.selectedRecordStatuses = [];
    $scope.officesMap = new Map($scope.offices.map(i => [i.id, i.label])) || {};
    $scope.payersMap = new Map(DatabaseApi.payers().map(i => [i.id, i.name])) || {};
    $scope.caregiversMap = DatabaseApi.caregivers() || {};
    $scope.patientsMap = DatabaseApi.patients() || {};
    $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();
    $scope.hhaIntegrationGlobalFilter = { val: "" };

    $scope.integrationDatePicker = {
        from: new Date(LocalDate.now().minusWeeks(1).format(DateTimeFormatter.ofPattern("MM/dd/yyyy"))),
        to: new Date(LocalDate.now().format(DateTimeFormatter.ofPattern("MM/dd/yyyy")))
    }

    $scope.tablesMap = {
        'Patient': null,
        'Authorization': null,
        'POC': null
    }

    $scope.filterComponentOptions = {
        styleActive: true,
        scrollable: true,
        scrollableHeight: '300px',
        enableSearch: true
    };

    $scope.filterStatusOptions = {
        styleActive: true,
        singleSelection: true,
        selectionLimit: 1,
        smartButtonMaxItems: 1,
        closeOnSelect: true,
        showCheckAll: false,
        showUncheckAll: false,
        enableSearch: false
    };

    const initialize = () => {
        $scope.setTab("Authorization");
    }

    const initFilesTable = () => {
        const options = {
            count: 25,
            sorting: { dateTime: "desc" }
        };

        $scope.tablesMap[$scope.activeTab] = new NgTableParams(options, {
            counts: [],
            dataset: $scope.files
        });
    }

    $scope.applySearch = () => {
        getIntegrationFiles();
    }

    const getIntegrationFiles = () => {
        $scope.files = null;
        $scope.isLoading = true;

        let getFilesFunctionByTab;

        if ($scope.activeTab === "Patient") {
            getFilesFunctionByTab = hhaxIntegrationService.getPatientFiles($scope.dateRecordTimes, $scope.integrationDatePicker, $scope.selectedOffices, $scope.selectedPayers, $scope.selectedRecordStatuses);
        } else if ($scope.activeTab === "Authorization") {
            getFilesFunctionByTab = hhaxIntegrationService.getAuthorizationFiles($scope.dateRecordTimes, $scope.integrationDatePicker, $scope.selectedOffices, $scope.selectedPayers, $scope.selectedRecordStatuses);
        } else if ($scope.activeTab === "POC") {
            getFilesFunctionByTab = hhaxIntegrationService.getPOCFiles($scope.dateRecordTimes, $scope.integrationDatePicker, $scope.selectedOffices, $scope.selectedPayers, $scope.selectedRecordStatuses);
        }

        getFilesFunctionByTab.then(res => {
            $scope.files = res.data.records;
            initFilesTable();
            fillRecordDataByType();
            initSelectionLogic($scope.files);
            $scope.isLoading = false;
        }, function (err) {
            $scope.isLoading = false;
            toaster.pop('error', 'Something went wrong', 'could not get DATA');
        });
    }

    const initSelectionLogic = (items) => {
        $scope.selectionLogic = selectionLogic.createNewLogic(function (item) {
            $scope.selectionLogic.addItemToCollection(item);
        });

        if (items) {
            items.forEach((item) => {
                $scope.selectionLogic.initItem(item);
            });
        }
    }

    $scope.getUpdatedByName = (agencyMemberId) => {
        return $scope.agencyMembersNames[agencyMemberId];
    }

    $scope.getRowPatient = (patientId) => {
        if (patientId !== undefined) {
            const patient = $scope.patientsMap[patientId];

            if (patient !== undefined) {
                return `${patient.firstName} ${patient.lastName} (${patient.displayId})`;
            }
        }

        return patientId;
    }

    $scope.getRowPOCTasks = (row) => {
        const hhaxTasksNames = row.mergeablePatients[0].hhaxPOCTaskNames.split(",");
        const hhaxTasksIds = content.pocTasks.split("|");
        const medflytPOCItemsIds = row.mergeablePatients[0].medflytPOCItemIds;
        
        let tasksError;

        if (hhaxTasksNames.length !== hhaxTasksIds.length) {
            tasksError = `There are number of tasks that aren't mapped in medflyt`;
        }

        if (hhaxTasksIds.length !== medflytPOCItemsIds.length) {
            tasksError = `There are number of tasks that aren't mapped in medflyt`;
        }

        return tasksText;
    }

    $scope.openPatientInNewTab = (patientId)  => {
        $rootScope.openPatientNewTab(patientId)
    }

    $scope.toggleCalendarPopups = function (prop) {
        $scope.calendarPopups[prop] = !$scope.calendarPopups[prop];
    }

    const fillRecordDataByType = () => {
        if ($scope.files) {
            $scope.files.forEach(record => {
                const payer = $scope.payersMap.get(record.payerId);
                const officesNames = [];
                if (payer) {
                    record.payer = payer;
                }

                if ($scope.activeTab === "Patient") {
                    record.officesIds.forEach(office => {
                        officesNames.push($scope.officesMap.get(office));
                    })
                    
                    record.officesNames = officesNames.join('/');
                } else if (record.mergeablePatients.length === 1) {
                    record.officeName = $scope.officesMap.get(record.mergeablePatients[0].officeId) ?? "";
                }
            })
        }
    }

    $scope.onDateRangeChanged = (startDate, endDate) => {
        $scope.integrationDatePicker.from = startDate;
        $scope.integrationDatePicker.to = endDate;
    }

    $scope.applyHHAIntegrationGlobalSearch = () => {
        const filter = { $: $scope.hhaIntegrationGlobalFilter.val };
        if ($scope.tablesMap[$scope.activeTab]) {
            angular.extend($scope.tablesMap[$scope.activeTab].filter(), filter);
        }
    };

    $scope.setTab = (tabName) => {
        $scope.isLoading = true;
        $scope.activeTab = tabName;
        getIntegrationFiles();
    }

    $scope.openFileRecordModal = (record) => {
        $uibModal.open({
            templateUrl: 'admin/views/hha-integration/integration-file-record-modal.html',
            size: 'md',
            controller: 'integrationFileRecordModalCtrl',
            resolve: {
                record: () => record,
                updateBillingFileRecord: () => $scope.updateBillingFileRecord,
                hideEmptyFields: () => false
            },
            backdropClass: 'transparent-backdrop',
            windowClass: "modal modal-slide-in-right uib-side-modal uib-side-modal-wide integration-file-record-side-modal"
        });
    }

    $scope.openMergePatientModal = (record) => {
        const modal = $uibModal;
        if ($scope.activeTab === "Patient") {
            modal.open({
                templateUrl: 'admin/views/hha-integration/hha-integration-merge-patient-modal.html',
                size: 'lg',
                controller: 'hhaIntegrationMergePatientModal',
                resolve: {
                    record: () => record,
                    onMergeRecord: () => () => {
                        getIntegrationFiles();
                    }
                },
                backdropClass: 'transparent-backdrop',
                windowClass: "modal modal-slide-in-right uib-side-modal uib-side-modal-wide"
            });
        } if ($scope.activeTab === "Authorization") {
            if (record.mergeableAuthorizations.length > 0) {
            $scope.mergeAuthorization(record);

            } else {
                if ($rootScope.isPermittedByKey("edit_patient_page_authorizations")){
                    $scope.createNewAuthorization(record);
                } else {
                    toaster.pop("warning", "Permission Denied", "Could not create new authorization");
                }
            }


        } else if ($scope.activeTab === "POC") {
            if ($rootScope.isPermittedByKey("edit_patient_page_plan_of_care")){
                $scope.createNewPOC(record);
            } else {
                toaster.pop("warning", "Permission Denied", "Could not create new POC");
            }
        }
    }

    $scope.createNewPOC = async (record) => {
      $scope.isLoading = true;
      const patient = record.mergeablePatients[0];
      const patientId = patient.patientId;

      const initialFormData = {
        startDate: record.content.pocStartDate
          ? LocalDate.parse(record.content.pocStartDate)
          : null,
        endDate: record.content.pocStopDate ? LocalDate.parse(record.content.pocStopDate) : null,
        duties: mapPlanOfCareItemIdsToFormDuties(patient.medflytPOCItemIds)
      };

      const modalInstance = $uibModal.open({
        templateUrl: "admin/views/new-plan-of-care-modal.html",
        size: "lg",
        controller: "newPlanOfCareModalCtrl",
        controllerAs: "ctrl",
        resolve: {
          initialFormData: () => initialFormData,
          patientId: () => patientId,
          patientOfficeId: () => patient.officeId,
          itemsFromHHAXIntegration: () => patient.medflytPOCItemIds,
          itemsFromHHAXIntegrationNotMapped: () => patient.hhaxPOCTasksIdsNotMapped.join(", ")
        },
      });

      $scope.isLoading = false;

      modalInstance.result.then(
        (res) => {
          if (res === "success") {
            $scope.processRecords([record], "PROCESSED");
          }
        },
        (e) => {}
      );
    };


    $scope.periodTypesOptions = {
        DAILY: 'Daily',
        WEEKLY: 'Weekly',
        MONTHLY: 'Monthly',
        ENTIRE_PERIOD: 'Entire period'
    };
    $scope.firstDayOfWeekOptions =  {
        SUNDAY: "Sunday",
        MONDAY: "Monday",
        TUESDAY: "Tuesday",
        WEDNESDAY: "Wednesday",
        THURSDAY: "Thursday",
        FRIDAY: "Friday",
        SATURDAY: "Saturday",
    };

    $scope.createNewAuthorization = async (record) => {
        $scope.isLoading = true;

        const { recordContractTypesIds, recordServiceCodeIds } = await hhaxIntegrationService.getHHAXIntegrationAuthRecordExtra(record.id);

        const patientId = record.mergeablePatients[0].patientId;
        const recordContent = record.content;
        const contracts = await getPatientContracts(patientId, recordContractTypesIds);
        const serviceCodes = DatabaseApi.activeServiceCodes().filter(serviceCode => recordServiceCodeIds.includes(serviceCode.id));

        var newScope = $scope.$new();
        newScope.patientContracts = contracts;
        newScope.periodTypesOptions = $scope.periodTypesOptions;
        newScope.firstDayOfWeekOptions = $scope.firstDayOfWeekOptions;
        newScope.integrationAuth = true;
        newScope.authModel = {
            code: recordContent.authorizationNumber,
            patientContractId: contracts.length === 1 ? contracts[0].id : null,
            serviceCode: serviceCodes.length === 1 ? serviceCodes[0].id : null,
            startDate: recordContent.fromDate,
            endDate: recordContent.toDate,
            periodType: recordContent.authorizationType.replaceAll(' ', '_').toUpperCase(),
            note: {noteRichText: recordContent.notes},
            hours: $scope.calculateHoursForPeriod(record),
            monday: parseFileHours(recordContent.monHours) ?? 0,
            tuesday: parseFileHours(recordContent.tueHours) ?? 0,
            wednesday: parseFileHours(recordContent.wedHours) ?? 0,
            thursday: parseFileHours(recordContent.thuHours) ?? 0,
            friday: parseFileHours(recordContent.friHours) ?? 0,
            saturday: parseFileHours(recordContent.satHours) ?? 0,
            sunday: parseFileHours(recordContent.sunHours) ?? 0,
            maxHours: recordContent.authorizationType === "Entire Period" ? recordContent.hoursPerAuthPeriod : null,
            dxCodes: record.dxCodes,
            isFromHHAIntegration: true
        }

        $uibModal.open({
            templateUrl: 'admin/views/authorization-setup-modal.html',
            controller: 'authorizationSetupModalCtrl',
            scope: newScope,
            size: 'lg',
            resolve: {
                fields: function () { return DatabaseApi.formFields('Authorization'); },
                data: function () { return newScope.authModel },
                serviceCodes: function () { return serviceCodes},
                patientId: function () { return patientId},
                onCreatedAuthorization: () => () => {
                    $scope.processRecords([record], 'PROCESSED')
                }
            }
        });

        $scope.isLoading = false;
    }

    $scope.mergeAuthorization = async (record) => {
        const { recordServiceCodeIds } = await hhaxIntegrationService.getHHAXIntegrationAuthRecordExtra(record.id);
        record = {...record, serviceCodeIds: recordServiceCodeIds, periodHours: $scope.calculateHoursForPeriod(record)};

        $uibModal.open({
            templateUrl: 'admin/views/hha-integration/hhax-merge-auth-modal.html',
            size: 'lg',
            controller: 'hhaxMergeAuthModal',
            resolve: {
                onCreateAuth: () => () => {
                    $scope.createNewAuthorization(record);
                },
                onIgnoreAuth: () => () => {
                    $scope.processRecords([record], 'IGNORED');
                },
                onFinishAuth: () => () => {
                    $scope.processRecords([record], 'PROCESSED');
                },
                onViewRecordDetails: () => () => {
                    $scope.openFileRecordModal(record)
                },
                record: () => record
            },
            windowClass: "modal modal-slide-in-right patient-modal"
        });
    }

    const sortContracts = (contracts) => {
        return contracts.sort((a,b) => {
            if (a.isPrimary && !b.isPrimary) {
                return -1;
            }
            if (!a.isPrimary && b.isPrimary) {
                return 1;
            }
            return new Date(b.endDate) - new Date(a.endDate);
        })

    }

    const getPatientContracts = (patientId, contractTypesIds) => {
        return PatientModalService.getPatientContractExtended(patientId).then(contracts =>
                sortContracts(contracts.filter(x => contractTypesIds.includes(x.contractTypeId)))
            );
    }

    $scope.calculateHoursForPeriod = (record) => {
        let hoursPerPeriod;
        switch (record.content.authorizationType){
            case "Entire Period":
                hoursPerPeriod = record.content.hoursPerAuthPeriod;
                break;
            case "Monthly":
                hoursPerPeriod = record.content.hoursPerMonth;
                break;
            case "Weekly":
                hoursPerPeriod = record.content.hoursPerWeek;
        }

        return hoursPerPeriod;
    }

    $scope.processRecords = (rows, markType) => {
        hhaxIntegrationService.processRecords(
            rows.map(row => row.id),
            $scope.activeTab,
            markType
        ).then(() => onSuccessProcess(markType)
        ).catch(onFailProcess);
    };

    const onSuccessProcess = (type) => {
        getIntegrationFiles();
        toaster.pop("success", "Success", `Record ${type}`);
    }

    const onFailProcess = () => {
        toaster.pop("error", "Something went wrong", "Could not process record");
    }

    const parseFileHours = (fileHours) => {
        if (fileHours === null) {
            return null;
        }

        if (fileHours === 0) {
            return 0;
        }

        const fileHoursString = fileHours.toString();

        let hours;

        if (fileHoursString.length === 3) {
        hours = fileHoursString.slice(0, 1);
        } else if (fileHoursString.length === 4) {
        hours = fileHoursString.slice(0, 2);
        }

        const minutes = fileHoursString.slice(-2);
        let result;

        if (minutes === '00') {
        result = hours;
        } else {
            if (minutes.slice(1, 2) !== '0') {
                result = `${hours}.${minutes}`;
            } else {
                result = `${hours}.${minutes.slice(0, 1)}`;
            }
        }

        return parseFloat(result);
    }

    $scope.downloadTable = () => {
        $scope.loadingCSV = true;
        const rows = [];
        const titles = ['Record ID', 'Payer', 'Patient MF ID', 'HHAX Admission Id', 'Patient First Name', 'Patient Last Name', 'Office'];

        switch ($scope.activeTab) {
            case 'Patient':
                titles.push('Medicaid Number');
                titles.push('Member Id');
                titles.push('Created/Modified Date');
                break;
            case 'Authorization':
                titles.push('Authorization Number');
                titles.push('Period Type');
                titles.push('Period Hours');
                titles.push('From');
                titles.push('To');
                titles.push('Service Code');
                titles.push('DX Codes');
                titles.push('Created/Modified Date');
                titles.push('Notes');
                break;
            case 'POC':
                titles.push('Start Date');
                titles.push('End Date');
                titles.push('Tasks');
                titles.push('Created Date');
                break;
            default:
                break;
        }

        titles.push('MF Updated At');
        titles.push('MF Updated By');

        rows.push(titles);

        $scope.selectionLogic.getSelectedItems().forEach(dataRow => {
            const patientId = dataRow.mergeablePatients.length === 1 ? dataRow.mergeablePatients[0].patientId : null;
            const patient = patientId !== null ? $scope.patientsMap[patientId] : null;
            const csvRow = [];

            csvRow.push(`${dataRow.id}`);
            csvRow.push(`${dataRow.payer}`);
            csvRow.push(`${patientId !== null ? patientId : ''}`);
            csvRow.push(`${dataRow.content.admissionId ?? ''}`);
            csvRow.push(`${patient?.firstName ?? ''}`);
            csvRow.push(`${patient?.lastName ?? ''}`);

            switch ($scope.activeTab) {
                case 'Patient':
                    csvRow.push(`${dataRow.officesNames}`);
                    csvRow.push(`${dataRow.content.medicaidNumber}`);
                    csvRow.push(`${dataRow.content.mrNumber}`);
                    csvRow.push($filter("mfShortDate")(dataRow.content.modifiedDate) || '');
                    break;
                case 'Authorization':
                    csvRow.push(`${dataRow.officeName === undefined ? '' : dataRow.officeName}`);
                    csvRow.push(`${dataRow.content.authorizationNumber}`);
                    csvRow.push(`${dataRow.content.authorizationType}`);
                    csvRow.push(`${$scope.calculateHoursForPeriod(dataRow)}`);
                    csvRow.push($filter("mfShortDate")(dataRow.content.fromDate) || '');
                    csvRow.push($filter("mfShortDate")(dataRow.content.toDate) || '');
                    csvRow.push(`${dataRow.content.billingServiceCode}`);
                    csvRow.push(`${dataRow.dxCodes.join(', ')}`);
                    csvRow.push($filter("mfShortDate")(dataRow.content.modifiedDate) || '');
                    csvRow.push(`${dataRow.content.notes?.replaceAll(", ", " ") ?? ''}`);
                    break;
                case 'POC':
                    csvRow.push(`${dataRow.officeName}`);
                    csvRow.push($filter("mfShortDate")(dataRow.content.pocStartDate) || '');
                    csvRow.push($filter("mfShortDate")(dataRow.content.pocStopDate) || '');
                    csvRow.push(`${dataRow.mergeablePatients.length === 1 ? row.mergeablePatients[0].hhaxPOCTaskNames : ""}`);
                    csvRow.push($filter("mfShortDate")(dataRow.content.createdDate) || '');
                    break;
                default:
                    break;
            }

            csvRow.push($filter("mfShortDate")(dataRow.updatedAt) || '');
            csvRow.push(`${$scope.getUpdatedByName(dataRow.updatedBy) ?? ''}`);

            rows.push(csvRow);
        });

        let csvContent = "data:text/csv;charset=utf-8,";
        rows.forEach(function (rowArray) {
            const row = rowArray.join(",");
            csvContent += row + "\r\n";
        });

        const encodedUri = encodeURI(csvContent);
        let link = document.createElement("a");
        link.setAttribute("href", encodedUri);
        link.setAttribute("download", `medflyt-export-HHAX-${$scope.activeTab}-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`);
        document.body.appendChild(link);

        link.click();
        $scope.loadingCSV = false;
    };

    $rootScope.$on("got_caregivers_data", function (event) {
        $scope.caregiversMap = DatabaseApi.caregivers() || {};
    });

    initialize();
};

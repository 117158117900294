import { useState } from "react";

type LocalFilters<$Object, $Filters> = {
  [P in keyof $Filters]: {
    value?: $Filters[P];
    filter: (row: $Object, currentFilter: $Filters[P]) => boolean;
  };
};

const useLocalFilters = <$Object, $Filters>(params: LocalFilters<$Object, $Filters>) => {
  const [state, setState] = useState(params);

  const handleChangeFilter = <T extends keyof LocalFilters<$Object, $Filters>>(
    path: T,
    value: LocalFilters<$Object, $Filters>[T]["value"]
  ) =>
    setState((prev) => {
      const clone = { ...prev };
      const curr = clone[path];
      curr.value = value;
      return clone;
    });

  const applyFilters = (list: $Object[]): $Object[] => {
    let filtered = list;
    for (const key of Object.keys(state)) {
      const val = state[key as keyof typeof state];
      const filterValue = val.value;
      if (val !== undefined && filterValue !== null && filterValue !== undefined) {
        filtered = filtered.filter((x) => val.filter(x, filterValue));
      }
    }
    return filtered;
  };

  return [applyFilters, handleChangeFilter, state] as const;
};

export default useLocalFilters;

//! @ngInject
export function sendEncodedPdfModalCtrl($scope, DatabaseApi, $rootScope, toaster, $uibModalInstance) {

    $scope.req = {
        attachmentName: $scope.attachmentName
    };

    let url;
    let reqObj

        url = 'agencies/' + $rootScope.agencyId + '/agency_members/' +
        $rootScope.agencyMemberId + '/send_encoded_pdf/' + $scope.type;

        const encodedPdf = $scope.type === 'email' ? $scope.encodedPdf.split("base64,")[1] :
        $scope.encodedPdf
        reqObj = Object.assign($scope.req, {
            encodedPdf: encodedPdf,
        });
    

    $scope.sendPdf = () => {
        $scope.isLoading = true;
        if ($rootScope.isOnboardingAgency){ 
            $scope.setOnboardingAgencyrequest();
        }
        DatabaseApi.post(url, reqObj).then(function () {
            toaster.pop('success', "Success", "Pdf sent successfully");
            $scope.closeModal();
        }, function () {
            toaster.pop('error', 'Failed, please try again');
            $scope.isLoading = false;
        });
    }

    $scope.setOnboardingAgencyrequest = () => {
        url = 'agencies/' + $rootScope.agencyId + '/agency_members/' +
        $rootScope.agencyMemberId + '/faxes/send_document';

        reqObj = {
            file: $scope.encodedPdf,
            fileName: $scope.fileName,
            faxNumber: $scope.req.phoneNumber,
            relatedEntity: $scope.relatedEntity,
            recipient: $scope.req.name,
            comment: $scope.req.body,
            subject: $scope.attachmentName
        };
    }

    $scope.closeModal = () => {
        $uibModalInstance.close();
    };
};

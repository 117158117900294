import angular from "angular";
import { profileFaxesTable } from "./components/profile-faxes-table/profile-faxes-table.component";
import { FaxesService } from "./faxes.service";

export function registerFaxesModule() {
    return (
      angular
        .module("dashboard")
  
        //  Services
        .service("faxesService", FaxesService)
  
        // Components
        .component("faxesTable", profileFaxesTable)
    );
  }
  
import { URLResponse } from "../../../scripts/messages/caregiver";
import { CaregiverId } from "../../../scripts/messages/ids";
import { Api } from "../../../scripts/services/Api";
import { Endpoint } from "../../../scripts/services/endpoint.service";
import { BankAccountInformationForm, BankAccountInformationResponse, BankAccountTypesResponse, BanksResponse } from "../components/caregiver-direct-deposit-info/caregiver-direct-deposit-info.component.types";

//! @ngInject
export class CaregiverService {
    static readonly $name = "caregiverService";
    
    constructor(
        private $rootScope: ng.IRootScopeService,
        private endpoint: Endpoint,
        private api: Api,
        private GoogleAddressService: any
    ) {}

    updateField = async (caregiverId: CaregiverId, field: string, value: unknown) => {
        const mutate = (() => {
            if (field === "certifications") {
                return this.api.put;
            }

            return this.api.patch;
        })();

        const endpoint = (() => {
            if (field === "certifications") {
                return this.endpoint({
                    path: "agencies/:agencyId/agency_members/:agencyMemberId/caregivers/:caregiverId/certifications",
                    params: {
                        agencyId: this.$rootScope.agencyId,
                        agencyMemberId: this.$rootScope.agencyMemberId,
                        caregiverId: caregiverId,
                    }
                });
            }

            return this.endpoint({
                path: "caregiver/:caregiverId",
                params: {
                    caregiverId: caregiverId,
                }
            });
        })();

        const body = await (async () => {
            if (field === "address") {
                const address = await this.GoogleAddressService.getAddressComponentsFromMedflytGeoCode(value)

                return {
                    address: address.formatedAddressDetails.fullAddress,
                    addressGeoLocation: address.formatedAddressDetails.location,
                    addressComponents: address,
                };
            }

            return {[field]: value};
        })();

        return mutate(endpoint, body);
    };

    getSignedPhotoUrl = (url: string) => {
        const endpoint = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/get_signed_photo_url",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId
            },
            queries: {
                url: url
            }
        });

        return this.api.get<URLResponse>(endpoint).then(({data}) => data.url)
    }

    getBanks = () => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/insta_pay_info/banks",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
            },
        });
      
        return this.api.get<BanksResponse>(url)
            .then(({ data }) => data?.banks)
    }

    getBankAccountTypes = () => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/insta_pay_info/bank_account_types",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId,
            },
        });
      
        return this.api.get<BankAccountTypesResponse>(url)
            .then(({ data }) => data?.accountTypes)
    }

    getBankAccountInformation = (caregiverId: CaregiverId) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/insta_pay_info/bank_information",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId
            },
            queries: {
                caregiverId: CaregiverId.unwrap(caregiverId).toString()
            }
        });
      
        return this.api.get<BankAccountInformationResponse>(url)
            .then(({ data }) => data?.bankAccountInformation)
    }

    updateBankAccountInformation = (caregiverId: CaregiverId, body: BankAccountInformationForm) => {
        const url = this.endpoint({
            path: "agencies/:agencyId/agency_members/:agencyMemberId/insta_pay_info/bank_information",
            params: {
                agencyId: this.$rootScope.agencyId,
                agencyMemberId: this.$rootScope.agencyMemberId
            },
            queries: {
                caregiverId: CaregiverId.unwrap(caregiverId).toString()
            }
        });

        return this.api.post(url, body);
    }
}
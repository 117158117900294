import { formatPhoneNumber, getLabelForNotIdentifiedPhoneNumber } from "../../call-center.utils";
import { InboundCallSocketPayload } from "../call-center.types";
import "./call-center-incoming-call-modal.component.scss";

interface ComponentOptions extends ng.IComponentOptions {
  $name: string;
}

//! @ngInject
class Controller implements ng.IComponentController {
  static readonly $name = "CallCenterIncomingCallModal";
  resolve!: { callDetails: InboundCallSocketPayload };
  modalInstance!: ng.ui.bootstrap.IModalInstanceService;
  details!: string;
  label!: string;
  teamName!: string;

  ringtone = new Audio("../admin/sounds/ringtone.mp3");

  $onInit(): void {
    this.ringtone.loop = true;
    this.ringtone.play();
    const callData = this.resolve.callDetails;
    switch (callData.additionalData.type) {
      case "Patient": {
        const patientName = callData.additionalData.patientName;
        const patientId = callData.additionalData.id;
        const patientStatus = callData.additionalData.patientStatus;
        const teamName = callData.teamName;
        this.details = `Patient - ${patientName} (${patientId})`;
        this.label = `Status: ${patientStatus}`;
        this.teamName = `Team: ${teamName}`;
        break;
      }
      case "AgencyMember": {
        const agencyMemberName = callData.additionalData.agencyMemberName;
        const agencyMemberId = callData.additionalData.id;
        this.details = `Agency Member - ${agencyMemberName} (${agencyMemberId})`;
        break;
      }
      case "NotIdentifiedPhoneNumber":
        this.details = formatPhoneNumber(callData.additionalData.source);
        this.label = getLabelForNotIdentifiedPhoneNumber(callData.additionalData.notIdentifiedType);
        this.teamName = `Team: ${callData.teamName}`;
        break;
      default:
        this.details = "";
    }
  }

  handleAnswer = () => {
    this.ringtone.pause();
    this.modalInstance.close("Answer");
    this.ringtone.pause();
  };

  handleReject = () => {
    this.ringtone.pause();
    this.modalInstance.close("Reject");
    this.ringtone.pause();
  };

  $onDestroy = () => {
    this.ringtone.pause();
  };
}

export const CallCenterIncomingCallModalComponent: ComponentOptions = {
  $name: "callCenterIncomingCallModal",
  templateUrl:
    "admin/modules/call-center/components/call-center-incoming-call-modal/call-center-incoming-call-modal.component.html",
  controller: Controller,
  controllerAs: "ctrl",
  bindings: {
    modalInstance: "<",
    resolve: "<",
  },
};

import { CloseIcon } from "@chakra-ui/icons";
import { Box, Center, Divider, Flex, IconButton, Portal, useToast } from "@chakra-ui/react";
import { noop } from "@chakra-ui/utils";
import { Instant } from "@js-joda/core";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import React, { useState } from "react";
import { match } from "ts-pattern";
import { Messages } from "../../../core/api";
import EntityCard, {
  AgencyMemberEntity,
  CaregiverEntity,
  Entity,
  EntityWithStatus,
  NotIdentifiedPhoneNumberEntity,
  PatientEntity,
} from "../../../shared/components/EntityCard";
import LoadingPage from "../../../shared/components/LoadingPage";
import useApi from "../../../shared/hooks/useApi";
import useLiveCallTicketIds from "../../../shared/hooks/useLiveCallTicketIds";
import useAuthData from "../../../shared/hooks/useAuthInfo";
import useSocketEvent from "../../../shared/hooks/useSocketEvent";
import useUnstableAngularizeDigestWorkaround from "../../../shared/hooks/useUnstableAngularizeDigestWorkaround";
import MaximizeIcon from "../../../shared/icons/MaximizeIcon";
import MinimizeIcon from "../../../shared/icons/MinimizeIcon";
import { queryKeys } from "../../../shared/query-keys";
import {
  AgencyMemberId,
  CaregiverId,
  CommCenterMessageId,
  CommCenterTeamId,
  CommCenterTeamMemberId,
  CommCenterTicketId,
  NoteSubjectId,
  PatientId,
} from "../../../shared/schema/schema";
import { formatErrorResponse } from "../../../shared/utils/format-response-error";
import { getFullName } from "../../../shared/utils/get-full-name";
import { loadable } from "../../../shared/utils/loadable";
import { optimisticUpdate } from "../../../shared/utils/optimistic-update";
import useTicketViewMutation from "../hooks/useTicketViewMutation";
import { CreateMessageRequest } from "../pages/CommunicationCenterTicket/CommunicationCenterTicketRoute";
import {
  AttachmentMimeType,
  buildAttachmentsPayload,
  getCommCenterTeamMemberIdByAgencyMemberId,
  getLatestCommCenterMessageByCaregiver,
  getPayloadTypeByMimeType,
  NewTicketRequestBody,
  sortTicketsByLastMessage,
} from "../utils/communication-utils";
import TicketsBox from "./TicketsBox";
import usePreSignedUrl from "../../../shared/hooks/usePreSignedUrl";
import usePreSignUpload from "../../../shared/hooks/usePreSignUpload";

type BaseProps = {
  onClose: () => void;
  defaultTicketId?: CommCenterTicketId;
  defaultMessage?: string;
  defaultLabel?: Messages["CommCenterLabel"];
};
type WithCaregiverId = {
  caregiverId: CaregiverId;
  patientId: undefined;
  agencyMemberId: undefined;
  notIdentifiedPhoneNumber: undefined;
  primaryEntity: "Caregiver";
};
type WithPatientId = {
  caregiverId: undefined;
  patientId: PatientId;
  agencyMemberId: undefined;
  notIdentifiedPhoneNumber: undefined;
  primaryEntity: "Patient";
};
type WithNotIdentifiedPhoneNumber = {
  primaryEntity: "NotIdentifiedPhoneNumber";
  caregiverId: undefined;
  patientId: undefined;
  agencyMemberId: undefined;
  notIdentifiedPhoneNumber: string;
};
type WithCaregiverIdAndPatientId_CaregiverPrimary = {
  caregiverId: CaregiverId;
  patientId: PatientId;
  agencyMemberId: undefined;
  notIdentifiedPhoneNumber: undefined;
  primaryEntity: "Caregiver";
};
type WithCaregiverIdAndPatientId_PatientPrimary = {
  caregiverId: CaregiverId;
  patientId: PatientId;
  agencyMemberId: undefined;
  notIdentifiedPhoneNumber: undefined;
  primaryEntity: "Patient";
};
type WithAgencyMemberId = {
  caregiverId: undefined;
  patientId: undefined;
  notIdentifiedPhoneNumber: undefined;
  primaryEntity: "AgencyMember";
  agencyMemberId: AgencyMemberId;
};
type Props = BaseProps &
  (
    | WithCaregiverId
    | WithPatientId
    | WithNotIdentifiedPhoneNumber
    | WithCaregiverIdAndPatientId_CaregiverPrimary
    | WithCaregiverIdAndPatientId_PatientPrimary
    | WithAgencyMemberId
  );

const CommCenterChatWrapper = (props: Props) => {
  useUnstableAngularizeDigestWorkaround();

  const { api } = useApi();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { agencyMember } = useAuthData();

  const [isMinimized, setIsMinimized] = useState(false);

  const [activeTicketId, setActiveTicketId] = useState<CommCenterTicketId | null>(
    props.defaultTicketId ?? null
  );

  const [attachments, setAttachments] = React.useState<File[]>([]);
  const preSignedFiles = usePreSignedUrl(attachments);
  const uploadAttachments = usePreSignUpload();

  const handleRemoveAttachment = (attachment: File) => {
    setAttachments(attachments.filter((i) => i !== attachment));
  };

  const handleRemoveAllAttachments = () => {
    setAttachments([]);
  };

  const handleAddFile = (newFile: File) => {
    setAttachments([...attachments, newFile]);
  };

  const teams = useQuery({
    queryKey: queryKeys.commCenter.teams(),
    queryFn: () => api.get("./comm_center/teams", {}),
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center teams",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const labels = useQuery({
    queryKey: queryKeys.commCenter.labels(),
    queryFn: () => api.get("./comm_center/labels", {}),
    select: (response) => response.labels,
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Error in fetching communication center labels",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const caregiverEntity = useQuery({
    queryKey: queryKeys.caregiver.get(props.caregiverId!),
    enabled: props.caregiverId !== undefined,
    queryFn: () => {
      return api.get("/agencies/:agencyId/caregivers/:caregiverId", {
        path: { caregiverId: props.caregiverId! },
      });
    },
    select: (caregiver): EntityWithStatus<CaregiverEntity> => ({
      type: "Caregiver",
      id: caregiver.id,
      displayId: caregiver.displayId,
      status: caregiver.status,
      photoUrl: caregiver.photoUrl,
      fullName: getFullName(caregiver),
    }),
    onError: (error) => {
      toast({
        title: `Couldn't get caregiver details for caregiver ${props.caregiverId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const agencyMemberEntity = useQuery({
    enabled: props.agencyMemberId !== undefined,
    queryKey: queryKeys.agencyMembers.list(),
    queryFn: () => api.get("/agencies/:agencyId/agency_members", {}),
    staleTime: Infinity,
    select: (data): EntityWithStatus<AgencyMemberEntity> | undefined => {
      const agencyMember = data.agencyMembers.find(
        (agencyMember) => agencyMember.id === props.agencyMemberId
      );
      return agencyMember !== undefined
        ? {
            status: "Agency Member",
            type: "Agency Member",
            id: agencyMember.id,
            fullName: getFullName(agencyMember),
            photoUrl: agencyMember.photoUrl,
          }
        : undefined;
    },
  });

  const patientEntity = useQuery({
    queryKey: queryKeys.patient.get(props.patientId!),
    enabled: props.patientId !== undefined,
    queryFn: () => {
      return api.get("/agencies/:agencyId/patients/:patientId", {
        path: { patientId: props.patientId! },
      });
    },
    select: (patient): EntityWithStatus<PatientEntity> => ({
      type: "Patient",
      id: patient.id,
      displayId: patient.displayId,
      status: patient.status,
      fullName: getFullName(patient),
      gender: patient.gender,
    }),
    onError: (error) => {
      toast({
        title: `Couldn't get patient details for patient ${props.patientId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const notIdentifiedPhoneNumberEntity:
    | EntityWithStatus<NotIdentifiedPhoneNumberEntity>
    | undefined =
    props.notIdentifiedPhoneNumber === undefined
      ? undefined
      : {
          type: "NotIdentifiedPhoneNumber",
          phoneNumber: props.notIdentifiedPhoneNumber,
          status: null,
        };

  const getPrimaryEntityFromQueriesData = (
    caregiverEntitiyData: Entity | undefined,
    patientEntityData: Entity | undefined,
    notIdentifiedPhoneNumberEntity: Entity | undefined,
    agencyMemberEntity: Entity | undefined
  ) => {
    const primaryEntity = (() => {
      switch (props.primaryEntity) {
        case "Patient":
          return patientEntityData;
        case "NotIdentifiedPhoneNumber":
          return notIdentifiedPhoneNumberEntity;
        case "Caregiver":
          return caregiverEntitiyData;
        case "AgencyMember":
          return agencyMemberEntity;
        default:
          return undefined;
      }
    })();

    if (primaryEntity === undefined) {
      throw new Error("No related primary entity found!");
    }

    return primaryEntity;
  };

  const getSecondaryEntityFromQueriesData = (
    caregiverEntitiyData: Entity | undefined,
    patientEntityData: Entity | undefined
  ) => {
    if (!props.primaryEntity) return;

    const secondaryEntity =
      props.primaryEntity === "Patient" ? caregiverEntitiyData : patientEntityData;

    return secondaryEntity;
  };

  const relatedTicketsQueryParams = match(props)
    .with({ primaryEntity: "Patient" }, (x) => ({
      patientId: [x.patientId],
    }))
    .with({ primaryEntity: "Caregiver" }, (x) => ({
      caregiverId: [x.caregiverId],
    }))
    .with({ primaryEntity: "NotIdentifiedPhoneNumber" }, (x) => ({
      relatedNotIdentifiedPhoneNumber: [x.notIdentifiedPhoneNumber],
    }))
    .with({ primaryEntity: "AgencyMember" }, (x) => ({
      assignee: [x.agencyMemberId],
    }))
    .exhaustive();

  const relatedTickets = useQuery({
    queryKey: queryKeys.commCenter.search(relatedTicketsQueryParams),
    enabled:
      (props.caregiverId ??
        props.patientId ??
        props.notIdentifiedPhoneNumber ??
        props.agencyMemberId) !== undefined,
    queryFn: () => {
      return api.get("./comm_center/tickets", {
        query: relatedTicketsQueryParams,
      });
    },
    keepPreviousData: true,
    onError: (error) => {
      toast({
        title: "Could not get related tickets",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    select: (response) => sortTicketsByLastMessage(response.tickets),
  });

  const activeTicket = useQuery({
    queryKey: queryKeys.commCenter.get(activeTicketId!),
    enabled: activeTicketId !== null,
    keepPreviousData: true,
    select: (data) => data.ticket,
    queryFn: () => {
      return api.get("./comm_center/tickets/:ticketId", {
        path: {
          ticketId: activeTicketId!,
        },
      });
    },
    onSuccess: ({ id, messages }) => {
      const latestReceivedMessage = getLatestCommCenterMessageByCaregiver(messages);

      if (latestReceivedMessage?.readAt === null) {
        markAsRead.mutate({
          ticketId: id,
          latestReceivedMessageId: latestReceivedMessage.id,
        });
      }
    },
    onError: (error) => {
      toast({
        title: `Error in fetching ticket ${activeTicketId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const createTicket = useMutation({
    mutationFn: (newTicketRequest: NewTicketRequestBody) => {
      return api.post(
        "/agencies/:agencyId/comm_center_team_members/:commCenterTeamMemberId/comm_center/tickets",
        {
          path: {
            commCenterTeamMemberId:
              getCommCenterTeamMemberIdByAgencyMemberId(teams.data?.teams ?? [], agencyMember.id) ??
              CommCenterTeamMemberId.wrap(-1),
          },
          body: newTicketRequest,
        }
      );
    },
    onSuccess: (response) => {
      toast({
        title: "Ticket created successfuly",
        status: "success",
        position: "top-right",
      });

      setActiveTicketId(response.ticketId);
      relatedTickets.refetch();
    },
    onError: (error) => {
      toast({
        title: "Could not create new ticket",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const markAllAsUnread = useMutation({
    mutationFn: (ticketId: CommCenterTicketId) => {
      return api.post("./comm_center/tickets/:ticketId/unread", {
        path: { ticketId },
      });
    },
    onMutate: () => {
      return optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === activeTicketId)
            ?.messages.forEach((message) => (message.readAt = null));
        },
      });
    },
    onSuccess: () => {
      toast({
        title: "All messages marked unread.",
        status: "success",
        position: "top-right",
      });

      if (activeTicketId !== null) {
        setTimeout(
          () => queryClient.invalidateQueries(queryKeys.commCenter.get(activeTicketId)),
          500
        );
      }

      setActiveTicketId(null);
    },
    onError: (error) => {
      toast({
        title: "Error marking ticket as unread",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
  });

  const submitMessage = useMutation({
    mutationFn: async (createParams: CreateMessageRequest) => {
      return api.post("./comm_center/tickets/:commCenterTicketId/message", {
        path: {
          commCenterTicketId: createParams.ticketId,
        },
        body: {
          message: [
            {
              type: "TEXT",
              message: createParams.message,
            },
            ...createParams.attachments,
          ],
        },
      });
    },
    onMutate: async (createParams) => {
      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          draft.tickets
            .find((ticket) => ticket.id === createParams.ticketId)
            ?.messages.push({
              id: CommCenterMessageId.wrap(Instant.now().toEpochMilli() * -1),
              createdBy: {
                type: "Agency Member",
                id: agencyMember.id,
                name: getFullName(agencyMember),
                photoUrl: agencyMember.photoUrl,
              },
              payload: [
                {
                  type: "TEXT",
                  message: createParams.message,
                },
                ...attachments.map((attachment) => ({
                  type: getPayloadTypeByMimeType(attachment.type as AttachmentMimeType),
                  url: URL.createObjectURL(attachment),
                })),
              ],
              labelId: null,
              readAt: null,
              messageActionId: null,
              ticketId: createParams.ticketId,
              createdAt: Instant.now(),
            });
        },
      });
      await uploadAttachments.mutateAsync(preSignedFiles.flatMap((a) => (a.data ? a.data : [])));

      return tickets;
    },
    onError: (error, _newChatMessage, context) => {
      queryClient.setQueryData(["tickets"], context?.previousValue);

      toast({
        title: "Could not send message.",
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });
    },
    onSuccess: () => {
      toast({
        title: "Message sent successfully",
        status: "success",
        position: "top-right",
      });

      relatedTickets.refetch();
      activeTicket.refetch();
    },
  });

  const editTicket = useMutation({
    mutationFn: (params: {
      ticketId: CommCenterTicketId;
      body: Messages["Partial<EditCommCenterTicketParams>"];
    }) => {
      return api.patch("./comm_center/tickets/:ticketId/edit", {
        path: { ticketId: params.ticketId },
        body: { params: params.body },
      });
    },
    onMutate: async (params) => {
      const ticket = optimisticUpdate<{ ticket: Messages["CommCenterTicket"] }>({
        queryClient,
        queryKey: queryKeys.commCenter.get(activeTicketId!),
        update: (draft) => {
          if (params.body.teamId !== undefined) {
            draft.ticket.relatedTeam.id = params.body.teamId;
            draft.ticket.assignedTo = null;
          }

          if (params.body.assignedToId !== undefined) {
            draft.ticket.assignedTo = {
              id: params.body.assignedToId,
              name: "Loading...",
            };
          }

          if (params.body.status !== undefined) {
            draft.ticket.status = params.body.status;
          }

          if (params.body.patientId !== undefined) {
            draft.ticket.relatedPatient =
              params.body.patientId === null
                ? null
                : {
                    id: params.body.patientId,
                    displayId: null,
                    gender: null,
                    name: "Loading...",
                    status: "ACTIVE",
                  };
          }

          if (params.body.caregiverId !== undefined) {
            draft.ticket.relatedCaregiver =
              params.body.caregiverId === null
                ? null
                : {
                    id: params.body.caregiverId,
                    displayId: null,
                    onboardingStageDetails: null,
                    photoUrl: null,
                    name: "Loading...",
                    status: "ACTIVE",
                  };
          }
        },
      });

      const tickets = optimisticUpdate<{ tickets: Messages["CommCenterTicket"][] }>({
        queryClient,
        queryKey: queryKeys.commCenter.search.K,
        update: (draft) => {
          const ticket = draft.tickets.find((ticket) => ticket.id === activeTicketId);

          if (ticket === undefined) {
            return;
          }

          if (params.body.teamId !== undefined) {
            ticket.relatedTeam.id = params.body.teamId;
            ticket.assignedTo = null;
          }

          if (params.body.assignedToId !== undefined) {
            ticket.assignedTo = {
              id: params.body.assignedToId,
              name: "Loading...",
            };
          }

          if (params.body.status !== undefined) {
            ticket.status = params.body.status;
          }
        },
      });

      return { ticket, tickets };
    },
    onSuccess: () => {
      toast({ title: "Ticket updated", status: "success", position: "top-right", duration: 2000 });
    },
    onError: (error, _, context) => {
      toast({
        title: `Could not update ticket ${activeTicketId}`,
        description: formatErrorResponse(error),
        status: "error",
        position: "top-right",
      });

      if (activeTicketId !== null) {
        queryClient.setQueryData(
          queryKeys.commCenter.get(activeTicketId),
          context?.ticket.previousValue
        );
      }

      queryClient.setQueryData(queryKeys.commCenter.search.K, context?.tickets.previousValue);
    },
  });

  const noteSettings = useQuery({
    queryKey: ["notes_settings"],
    queryFn: () => api.get("./notes_settings", {}),
  });

  const liveCallTickets = useLiveCallTicketIds();

  const noteSubjects: { label: string; value: NoteSubjectId }[] =
    noteSettings.data?.agencyNoteSubjects?.map((x) => ({
      label: x.text,
      value: x.id,
    })) ?? [];

  useSocketEvent({
    key: "CommCenterTicketUpdated",
    onEvent: (event) => {
      relatedTickets.refetch();

      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallParticipantEvent",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallEnd",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  useSocketEvent({
    key: "CallCenterCallStart",
    onEvent: (event) => {
      if (event.ticketId === activeTicketId) {
        activeTicket.refetch();
      }
    },
  });

  const markAsRead = useTicketViewMutation();

  const handleEditTicket = (
    ticketId: CommCenterTicketId,
    body: Messages["Partial<EditCommCenterTicketParams>"]
  ) => {
    editTicket.mutate({ ticketId, body });
  };

  const handleClickTicket = (newTicketId: CommCenterTicketId) => {
    setActiveTicketId(newTicketId);
  };

  const handleMarkAsUnread = (ticketId: CommCenterTicketId) => {
    markAllAsUnread.mutate(ticketId);
  };

  const handleSubmitNewMessage = async (ticketId: CommCenterTicketId, message: string) => {
    handleRemoveAllAttachments();
    const attachments = buildAttachmentsPayload(preSignedFiles);
    await uploadAttachments.mutateAsync(preSignedFiles.flatMap((a) => (a.data ? a.data : [])));
    submitMessage.mutate({ ticketId, message, attachments });
  };

  const handleCreateNewTicket = (newTicketRequest: NewTicketRequestBody) => {
    createTicket.mutate(newTicketRequest);
  };

  const areActionsDisabled = activeTicket.data?.status === "RESOLVED" || activeTicketId === null;

  const primaryEntityData =
    caregiverEntity.data ??
    patientEntity.data ??
    agencyMemberEntity.data ??
    notIdentifiedPhoneNumberEntity;

  return (
    <Portal>
      <Box
        id="comm-center-chat-window"
        borderTopRadius="2xl"
        position="fixed"
        bottom={39}
        right={55}
        zIndex={10000}
        bg="white"
        boxShadow="0 0 16px -2px rgba(0,0,0,0.25)"
        transition="all 250ms ease"
        w={isMinimized ? 300 : 1200}
        transform={isMinimized ? `translateY(calc(100% - 49px)) ` : undefined}
        sx={{
          "--max-chat-height": "60vh",
        }}
      >
        <Flex p={2}>
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<CloseIcon fontSize={12} />}
            onClick={props.onClose}
            borderRadius="xl"
          />
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<MinimizeIcon fontSize={18} />}
            onClick={() => setIsMinimized(true)}
            borderRadius="xl"
            hidden={isMinimized}
          />
          <IconButton
            aria-label="close"
            variant="ghost"
            icon={<MaximizeIcon fontSize={18} />}
            onClick={() => setIsMinimized(false)}
            borderRadius="xl"
            hidden={!isMinimized}
          />
        </Flex>

        <Divider />

        {relatedTickets.isLoading ||
        noteSettings.isLoading ||
        liveCallTickets.isLoading ||
        primaryEntityData === undefined ? (
          <Center maxH="65vh">
            <LoadingPage />
          </Center>
        ) : (
          <TicketsBox
            liveCallTicketIds={liveCallTickets.data ?? []}
            noteSubjects={noteSubjects}
            activeTicket={activeTicket.data ?? null}
            primaryEntity={loadable.resolve(
              getPrimaryEntityFromQueriesData(
                caregiverEntity.data,
                patientEntity.data,
                notIdentifiedPhoneNumberEntity,
                agencyMemberEntity.data
              )
            )}
            secondaryEntity={loadable.resolve(
              getSecondaryEntityFromQueriesData(caregiverEntity.data, patientEntity.data)
            )}
            defaultMessage={props.defaultMessage}
            defaultLabel={props.defaultLabel}
            label={activeTicket.data?.label?.name ?? null}
            settings={{
              assignedToId: activeTicket.data?.assignedTo?.id ?? null,
              status: activeTicket.data?.status ?? "NEW",
              teamId: activeTicket.data?.relatedTeam.id ?? CommCenterTeamId.wrap(0),
            }}
            onboardingStageName={
              activeTicket.data?.relatedCaregiver?.onboardingStageDetails?.name ?? null
            }
            entityCardAs={EntityCard}
            labels={labels.data?.filter((label) => label.active) ?? []}
            initialLabelId={
              labels.data?.find((label) => label.parent === null && !label.active)?.id ?? null
            }
            teams={teams.data?.teams ?? []}
            attachments={attachments}
            tickets={loadable.fromUndefined(relatedTickets.data)}
            isNewTicketOpen={activeTicketId === null}
            areActionsDisabled={areActionsDisabled}
            onRequestCloseNewTicket={noop}
            onChangeSettings={handleEditTicket}
            onClickTicket={handleClickTicket}
            onClickMarkAsUnread={handleMarkAsUnread}
            onSubmitNewMessage={handleSubmitNewMessage}
            onCreateNewTicket={handleCreateNewTicket}
            onClickNewTicket={() => setActiveTicketId(null)}
            onClickRemoveAttachment={handleRemoveAttachment}
            onSelectFile={handleAddFile}
          />
        )}
      </Box>
    </Portal>
  );
};

export default CommCenterChatWrapper;

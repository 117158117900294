import { AttachmentIcon } from "@chakra-ui/icons";
import { Button, IconButton, Popover, PopoverContent, PopoverTrigger } from "@chakra-ui/react";
import React from "react";
import BaselinePlayCircleIcon from "../icons/BaselinePlayCircleIcon";
import DocumentIcon from "../icons/DocumentIcon";
import PhotoFillIcon from "../icons/PhotoFillIcon";

interface Props {
  onSelectFile: (newFile: File) => void;
}

const FileAttachmentButton = (props: Props) => {
  const hiddenFileInput = React.useRef<HTMLInputElement>(null);

  const handleClick = (acceptedFiles: string) => {
    if (hiddenFileInput.current !== null) {
      hiddenFileInput.current.accept = acceptedFiles;
      hiddenFileInput.current.click();
    }
  };

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files;

    if (file === null || file.length === 0) {
      return;
    }

    props.onSelectFile(file[0]);
  };

  return (
    <Popover placement="top-end" closeOnBlur={true}>
      <PopoverTrigger>
        <IconButton aria-label="attachment" size="lg" rounded="2xl" icon={<AttachmentIcon />} />
      </PopoverTrigger>
      <PopoverContent border={0} backgroundColor="transparent" w={"36"}>
        <Button
          marginBottom={"1.5"}
          colorScheme="gray"
          size="md"
          leftIcon={<PhotoFillIcon />}
          onClick={() => handleClick("image/*")}
        >
          Photo
        </Button>
        <Button
          marginBottom={"1.5"}
          colorScheme="gray"
          size="md"
          leftIcon={<BaselinePlayCircleIcon />}
          onClick={() => handleClick("video/*")}
        >
          Video
        </Button>
        <Button
          colorScheme="gray"
          size="md"
          leftIcon={<DocumentIcon />}
          onClick={() => handleClick(".pdf")}
        >
          Document
        </Button>
        <input
          type="file"
          ref={hiddenFileInput}
          onChange={handleFileSelection}
          style={{ display: "none" }}
        />
      </PopoverContent>
    </Popover>
  );
};
export default FileAttachmentButton;

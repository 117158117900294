import _ from "lodash";

//! @ngInject
export function billingPatientSurplusDashboardCtrl(
  $scope,
  $rootScope,
  $filter,
  $timeout,
  DatabaseApi,
  toaster,
  NgTableParams,
  ngTableEventsChannel,
  patientService,
  PatientModalService,
  generalUtils,
  $uibModal,
  FilterUtils,
  Storage,
  $state
) {
  const universalBOM = "\uFEFF";
  $scope.isLoading = false;
  $rootScope.$on("got_patients_data", function (event) {
    $scope.patientsMap = DatabaseApi.patients();
  });

  $scope.tableParams = null;
  $scope.globalFilter = { val: "" };

  $scope.filterBy = {
    fromDate: new Date(),
    toDate: new Date(),
  };
  $scope.invoiceIdsSet = new Set();

  $scope.getPatientSurplusInvoicePdf = async (invoiceId, patientId) => {
    $scope.isLoading = true;
    const data = await patientService.getPatientSurplusInvoicePdfUrl(patientId, invoiceId);
    $scope.isLoading = false;
    window.open(data.url);
  };

  $scope.openPatientInNewTab = (patientId) => {
    $rootScope.openPatientNewTab(patientId);
  };

  $scope.openPatientModal = (patientId) => {
    $rootScope.openPatientModal(patientId);
  };

  const initialize = () => {
    // // Load filters
    const storageFilters = FilterUtils.rootFilters.billingPatientSurplusDashboardCtrl;
    if (storageFilters !== undefined) {
      $scope.filterBy = storageFilters;
    }

    $scope.loadItems();
    $scope.globalFilter.val = "";
  };

  $scope.$on("$destroy", function () {
    const filters = $scope.filterBy;
    FilterUtils.rootFilters.billingPatientSurplusDashboardCtrl = angular.copy(filters);
  });

  const initTable = (items) => {
    const options = {
      count: 50,
    };
    $scope.tableParams = new NgTableParams(options, {
      dataset: items,
    });
    $scope.filteredList = [];
  };

  const getTotalItemSummaries = (summaries) => {
    return summaries.reduce(
      (total, summary) => {
        if ($scope.availableSummaryDaysGroups.indexOf(summary.daysPastGroup) === -1) {
          $scope.availableSummaryDaysGroups.push(summary.daysPastGroup);
        }
        total.visitCount += summary.visitCount;
        total.billedAmount += summary.billedAmount;
        total.paidAmount += summary.paidAmount;
        return total;
      },
      { visitCount: 0, billedAmount: 0, paidAmount: 0 }
    );
  };

  const updateGroupsTotals = (items) => {
    $scope.summaryGroupTotals = $scope.availableSummaryDaysGroups.map((group) =>
      items.reduce(
        (groupSum, item) => {
          const groupFound = item.summaries.find((summary) => summary.daysPastGroup === group);
          if (groupFound !== undefined) {
            groupSum.visitCount += groupFound.visitCount;
            groupSum.billedAmount += groupFound.billedAmount;
            groupSum.paidAmount += groupFound.paidAmount;
          }
          return groupSum;
        },
        { visitCount: 0, billedAmount: 0, paidAmount: 0 }
      )
    );

    //   Sum a total of all rows totals
    $scope.summaryGroupTotals.push(
      items.reduce(
        (sumTotal, item) => {
          if (item.total !== undefined) {
            sumTotal.visitCount += item.total.visitCount;
            sumTotal.billedAmount += item.total.billedAmount;
            sumTotal.paidAmount += item.total.paidAmount;
          }
          return sumTotal;
        },
        { visitCount: 0, billedAmount: 0, paidAmount: 0 }
      )
    );
  };

  const sortNumbersArray = (arr) => {
    return arr.sort((a, b) => {
      if (a > b) {
        return 1;
      } else if (a < b) {
        return -1;
      } else {
        return 0;
      }
    });
  };

  $scope.$on("got_data", (event) => {
    $scope.loadItems();
  });

  $scope.loadAgencyMembers = () => {
    $scope.agencyMemberNamesMap = DatabaseApi.getAllAgencyMembersNames();
    $scope.assignedMembersOptions = DatabaseApi.getAgencyMembersArr().filter(
      (member) => member.status === "Active"
    );

    $scope.filteredAssignedMembersOptions = $scope.assignedMembersOptions
      .filter((agencyMember) => {
        return (
          agencyMember.officeIds.findIndex((agencyMemberOfficeId) =>
            $rootScope.user.agencyMember.officeIds.includes(agencyMemberOfficeId)
          ) !== -1
        );
      })
      .sort(generalUtils.sortByDisplayName);
  };

  $scope.loadItems = (search) => {
    $scope.isLoading = true;
    console.log($scope.filterBy);
    patientService
      .getPatientSurplusDashboard($scope.filterBy)
      .then((res) => {
        $scope.isLoading = false
        console.log(res);
        $scope.patientSurplusDashboardRecords = res.patientSurplusDashboardRecords;
        initTable($scope.patientSurplusDashboardRecords);
        
      })
      .catch((err) => {
        $scope.isLoading = false
        console.error(err);
        toaster.pop("error", "Something went wrong");
        
      })
      .finally(() => ($scope.isLoading = false));
  };

  $scope.applyGlobalSearch = (term) => {
    const filter = { $: term };
    if ($scope.tableParams) {
      angular.extend($scope.tableParams.filter(), filter);
    }
  };

  $scope.toggleBtnGroupValue = (paramName, toggledButton) => {
    switch ($scope.filterBy[paramName]) {
      case "": {
        $scope.filterBy[paramName] = toggledButton;
        break;
      }
      case null: {
        $scope.filterBy[paramName] = !toggledButton;
        break;
      }
      default: {
        $scope.filterBy[paramName] = toggledButton === $scope.filterBy[paramName] ? "" : null;
        break;
      }
    }
  };

  $scope.openModalToAddNoteByVisitId = (visitInstanceId) => {
    $scope.openModalToAddNotes([visitInstanceId]);
  };

  $scope.openModalViewNotesByVisitId = (visitInstanceId, notes) => {
    $uibModal.open({
      templateUrl: "admin/views/invoiced-visit-notes-modal.html",
      size: "lg",
      controller: "invoicedVisitNotesModalCtrl",
      resolve: {
        visitInstanceId: () => visitInstanceId,
        agencyMembers: () => $scope.assignedMembersOptions,
        notes: () => notes,
      },
    });
  };

  $scope.exportTable = (arType) => {
    if (arType !== "Summary") {
      $scope.exportTableDetailed();
    } else {
      $scope.exportTableSummary();
    }
  };

  $scope.exportTableSummary = () => {
    $scope.loadingCSV = true;

    const rows = [];

    // This is a duplicate of the html page logic.

    const titles = ["Contract"];
    for (let i = 0; i < $scope.availableSummaryDaysGroups.length; i++) {
      titles.push(`${i * 30}-${(i + 1) * 30} days`);
    }
    titles.push("Total");
    rows.push(titles);

    for (const dataRow of $scope.summaryTableData) {
      const row = [dataRow.contractTypeName];
      for (const group of $scope.availableSummaryDaysGroups) {
        row.push(
          '"' +
            $filter("centsToDollars")(
              dataRow[group] ? dataRow[group].billedAmount - dataRow[group].paidAmount : ""
            ) +
            '"'
        );
      }
      row.push(
        '"' +
          $filter("centsToDollars")(
            dataRow ? dataRow.total.billedAmount - dataRow.total.paidAmount : ""
          ) +
          '"'
      );

      rows.push(row);
    }

    const totalRow = [""];
    for (const groupTotal of $scope.summaryGroupTotals) {
      totalRow.push(
        '"' +
          $filter("centsToDollars")(
            groupTotal ? groupTotal.billedAmount - groupTotal.paidAmount : ""
          ) +
          '"'
      );
    }
    rows.push(totalRow);

    var csvContent = "";
    rows.forEach(function (rowArray) {
      var row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", getExportedFileName());
    document.body.appendChild(link);

    link.click();
    $scope.loadingCSV = false;
  };

  $scope.exportTableDetailed = () => {
    $scope.loadingCSV = true;
    const rows = [];
    const titles = [
      "Patient",
      // "Caregiver",
      "Visit Date",
      "Schedule Time",
      // "Recorded Time",
      "Contract",
      "Office",
      "Invoice",
      "Invoice Date",
      "Units",
      "Amount",
      "Paid",
      "Balance",
      "Payment Status",
      "No Payment Reason",
      "Collection Status",
      "Claim Status",
      "Check Numbers",
      "Claim Numbers",
      "Adjustment Code",
      "Last Export Date",
      "Export Dates",
      "Note",
      "Follow Up Date",
      "Follow Up For",
      "Note Created At",
      "Note Created By",
      "Notes",
    ].filter((title) => $scope.tableColumns[title]);
    rows.push(titles);

    // Filter table data by global filter
    const allAr = $filter("filter")($scope.invoicedVisits, $scope.globalFilter.val);

    allAr.forEach((dataRow) => {
      const csvRow = [];
      titles.forEach((title) => {
        if (!$scope.tableColumns[title]) {
          return;
        }
        let toPush = "";
        switch (title) {
          case "Patient":
            toPush = dataRow.patientName || "";
            break;
          // case "Caregiver":
          //     toPush = dataRow.caregiver.displayName || '';
          //     break;
          case "Visit Date":
            toPush = $filter("mfShortDate")(dataRow.visitDate) || "";
            break;
          case "Schedule Time":
            toPush = $filter("hourMinutesRange")(dataRow, "startTime", "endTime");
            break;
          // case "Recorded Time":
          //     toPush = $filter("hourMinutesRange")(dataRow, 'clockinTime', 'clockoutTime');
          //     break;
          case "Contract":
            toPush = dataRow.contractTypeName || "";
            break;
          case "Office":
            toPush = dataRow.officeName ? dataRow.officeName : "";
            break;
          case "Invoice":
            toPush = dataRow.invoiceId;
            break;
          case "Invoice Date":
            toPush = $filter("mfShortDate")(dataRow.invoiceCreatedAt) || "";
            break;
          case "Units":
            toPush = dataRow.billingUnitsThousandths / 1000 || "";
            break;
          case "Amount":
            toPush = $filter("centsToDollars")(dataRow.billedAmount) || 0;
            break;
          case "Paid":
            toPush = $filter("centsToDollars")(dataRow.payment) || 0;
            break;
          case "Balance":
            toPush = $filter("centsToDollars")(dataRow.balance) || 0;
            break;
          case "Payment Status":
            toPush = dataRow.paymentStatus || "";
            break;
          case "No Payment Reason":
            toPush = dataRow.latestNote ? dataRow.latestNote.arReasonText : "";
            break;
          case "Collection Status":
            toPush = dataRow.latestNote ? dataRow.latestNote.collectionStatusText : "";
            break;
          case "Claim Status":
            toPush = dataRow.latestNote ? dataRow.latestNote.claimStatusText ?? "" : "";
            break;
          case "Check Numbers":
            toPush = dataRow.checkNumbers
              ? dataRow.checkNumbers.map((c) => c.checkNumber).join(", ")
              : "";
            break;
          case "Claim Numbers":
            toPush = dataRow.claimNumbers ? dataRow.claimNumbers.join(", ") : "";
            break;
          case "Adjustment Code":
            toPush = dataRow.adjustment
              ? dataRow.adjustment.groupCode + ":" + dataRow.adjustment.reason
              : "";
            break;
          case "Last Export Date":
            toPush = dataRow.lastExportDate ? $filter("mfShortDate")(dataRow.lastExportDate) : "";
            break;
          case "Export Dates":
            toPush = dataRow.exportDates.map((date) => $filter("mfShortDate")(date)).join(", ");
            break;
          case "Note":
            toPush = dataRow.latestNote ? dataRow.latestNote.noteText : "";
            break;
          case "Follow Up Date":
            toPush = dataRow.latestNote
              ? $filter("mfShortDate")(dataRow.latestNote.followUpDate)
              : "";
            break;
          case "Follow Up For":
            toPush = dataRow.latestNote
              ? $scope.agencyMemberNamesMap[dataRow.latestNote.followUpFor]
              : "";
            break;
          case "Note Created At":
            toPush = dataRow.latestNote
              ? $filter("mfShortTime")(dataRow.latestNote.createdAt, ["withDate"])
              : "";
            break;
          case "Note Created By":
            toPush = dataRow.latestNote
              ? $scope.agencyMemberNamesMap[dataRow.latestNote.createdBy]
              : "";
            break;
          case "Check Numbers":
            toPush = dataRow.checkNumbers
              ? dataRow.checkNumbers.map((c) => c.checkNumber).join(", ")
              : "-";
            break;
          case "Claim Numbers":
            toPush = dataRow.claimNumbers ? dataRow.claimNumbers.join(", ") : "-";
            break;
          case "Last Export Date":
            toPush = dataRow.lastExportDate ? $filter("mfShortDate")(dataRow.lastExportDate) : "";
            break;
          case "Export Dates":
            toPush = dataRow.exportDates.map((date) => $filter("mfShortDate")(date)).join(", ");
            break;
          case "Notes":
            if (!dataRow.notes || !Array.isArray(dataRow.notes) || dataRow.notes.length === 0) {
              toPush = "-";
            } else {
              const amountText = `${dataRow.notes.length} note${
                dataRow.notes.length > 1 ? "s" : ""
              }`;
              const notesContents = dataRow.notes
                .map(
                  (note) =>
                    `${$filter("mfShortTime")(note.createdAt, ["withDate"])}: ${note.noteText}`
                )
                .join(" | ");
              toPush = `${amountText}: ${notesContents}`;
            }
            break;
          default:
            break;
        }

        csvRow.push('"' + toPush + '"');
      });

      rows.push(csvRow);
    });

    var csvContent = "";
    rows.forEach(function (rowArray) {
      var row = rowArray.join(",");
      csvContent += row + "\r\n";
    });

    var encodedUri = "data:text/csv;charset=utf-8," + encodeURIComponent(universalBOM + csvContent);
    var link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", getExportedFileName());
    document.body.appendChild(link);

    link.click();
    $scope.loadingCSV = false;
  };

  var getExportedFileName = function () {
    return `medflyt-export-surplus-invoices-${$filter("date")(new Date(), "yyyy-MM-dd")}.csv`;
  };

  $scope.goToInvoicePage = (invoiceId) => {
    $rootScope.openInvoiceModal({ invoiceId });
  };

  initialize();
}

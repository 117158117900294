import { DownloadIcon } from "@chakra-ui/icons";
import { ComponentWithAs, Flex, IconButton } from "@chakra-ui/react";
import React from "react";
import { useDownloadDocument } from "../hooks/useDownloadDocument";

const DocumentChatMessage = (props: {
  data: string;
  width: number;
  height: number;
  element?: React.ElementType;
}) => {
  const { download } = useDownloadDocument();
  const ObjectComponent = (props.element ?? <object />) as ComponentWithAs<"object">;

  return (
    <Flex alignItems="center" justifyContent="center">
      <Flex direction="column" align="center" gap={8}>
        <ObjectComponent data={props.data} height={props.height} width={props.width} />
        <IconButton
          icon={<DownloadIcon fontSize="xl" />}
          aria-label="download"
          colorScheme="blackAlpha"
          variant="outline"
          size="lg"
          rounded="full"
          onClick={() => download(props.data)}
        />
      </Flex>
    </Flex>
  );
};

export default DocumentChatMessage;

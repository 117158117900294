import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";

export function useCaregiversQuery() {
  const { api } = useApi();

  return useQuery({
    queryKey: queryKeys.caregiver.list(),
    queryFn: () => api.get("/agencies/:agencyId/caregivers", {}),
    staleTime: Infinity,
    select: (data) => data.caregivers,
  });
}

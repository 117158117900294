import { useState } from "react";
import { DialerTabsContext } from "../components/DialerTabs";

export const defaultDialerPadContext: DialerTabsContext = {
  hideAgencyMembers: false,
  hideCaregivers: false,
  hidePatients: false,
  hidePhoneNumber: false,
};

const useDialerTabsContext = (initialContext: DialerTabsContext = defaultDialerPadContext) => {
  const [context, setContext] = useState<DialerTabsContext>(initialContext);
  return [context, setContext] as const;
};

export default useDialerTabsContext;

import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { agencyMemberKeys } from "../agencyMembers.query-keys";

interface Props {
  searchTerm?: string;
}

const useAgencyMembersQuery = (props: Props) => {
  const { api } = useApi();
  return useQuery({
    queryKey: agencyMemberKeys.list(),
    queryFn: () => api.get("/agencies/:agencyId/agency_members", {}),
    staleTime: Infinity,
    select: (data) => {
      const { searchTerm } = props;
      if (searchTerm !== undefined && searchTerm !== "") {
        return data.agencyMembers.filter((x) =>
          `${x.firstName} ${x.lastName}`
            .toLocaleLowerCase()
            .includes(searchTerm.toLocaleLowerCase())
        );
      }
      return data.agencyMembers;
    },
  });
};

export default useAgencyMembersQuery;

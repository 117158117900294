import PhoneNumber from "awesome-phonenumber";

export function isValidNumber(number: string) {
  const phoneNumber = new PhoneNumber(number, "US");
  return phoneNumber.isValid();
}

export function formatE164(number: string) {
  const phoneNumber = new PhoneNumber(number, "US");
  return phoneNumber.getNumber("e164");
}

import React from "react";
import { Messages } from "../../../core/api";
import { CommCenterTicketId, NoteSubjectId } from "../../../shared/schema/schema";
import CompletedCallTicket from "./CallTicket/CompletedCallTicket";
import useCallTicketNotes from "./CallTicket/hooks/useCallTicketNotes";
import LiveCallTicket from "./CallTicket/LiveCallTicket";

interface Props {
  activeCallTicket: Messages["CommCenterTicket"] & {
    callSessionDetails: Messages["CallSessionDetails"];
  };
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
}

const CallTicket = (props: Props) => {
  const callNoteSectionState = useCallTicketNotes({
    noteSubjects: props.noteSubjects,
    ticket: props.activeCallTicket,
  });
  if (props.liveCallTicketIds.includes(props.activeCallTicket.id)) {
    return (
      <LiveCallTicket
        activeTicket={props.activeCallTicket}
        notesSectionData={callNoteSectionState}
      />
    );
  }

  return (
    <CompletedCallTicket
      activeTicket={props.activeCallTicket}
      notesSectionState={callNoteSectionState}
    />
  );
};

export default CallTicket;

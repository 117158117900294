import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { MessagePreSignedAttachments } from "../../modules/communication/utils/communication-utils";

export function usePreSignUpload() {
  const uploadAttachments = useMutation({
    mutationFn: async (attachments: MessagePreSignedAttachments[]) => {
      await Promise.all(
        attachments.map(async (attachment) => await axios.put(attachment.url, attachment.file))
      );
    },
  });

  return uploadAttachments;
}
export default usePreSignUpload;

import styled from "@emotion/styled";
import React from "react";
import { Shimmer } from "react-shimmer";
import useCacheSignedUrlQuery from "../hooks/useCacheSignedUrlQuery";

type Props = { width: number; height: number; src: string; fallback?: React.ReactNode };

const CachedSignedVideo = (props: Props) => {
  const { width, height, src, fallback } = props;
  const query = useCacheSignedUrlQuery(src);

  if (query.isLoading) {
    return <>{fallback}</>;
  }

  if (query.data !== undefined) {
    return <Video src={query.data} width={width} height={height} controls />;
  }

  return null;
};

export const CachedSignedVideoWithShimmer = (
  props: Omit<Props, "w" | "h"> & { width: number; height: number }
) => {
  return (
    <CachedSignedVideo
      {...props}
      fallback={<Shimmer width={props.width} height={props.height} />}
    />
  );
};

const Video = styled.video`
  width: 250px !important;
  height: 200px !important;
`;

export default CachedSignedVideo;

import React from "react";
import { Messages } from "../../../core/api";
import { CommCenterTicketId, NoteSubjectId } from "../../../shared/schema/schema";
import { isChatMessagesTicket, isPhoneCallTicket } from "../utils/communication-utils";
import CallTicket from "./CallTicket";
import ChatMessages from "./ChatMessages";
import { Text } from "@chakra-ui/react";

interface Props {
  ticket: Messages["CommCenterTicket"];
  noteSubjects: { label: string; value: NoteSubjectId }[];
  liveCallTicketIds: CommCenterTicketId[];
  attachments: File[];
  onSubmitNewMessage: (message: string) => void;
  onSelectFile: (newFile: File) => void;
  onClickRemoveAttachment: (attachment: File) => void;
}

const ActiveTicket = (props: Props): JSX.Element => {
  if (isChatMessagesTicket(props.ticket)) {
    return (
      <ChatMessages
        activeTicket={props.ticket}
        attachments={props.attachments}
        onClickRemoveAttachment={props.onClickRemoveAttachment}
        onSubmitNewMessage={props.onSubmitNewMessage}
        onSelectFile={props.onSelectFile}
      />
    );
  }
  if (isPhoneCallTicket(props.ticket)) {
    return (
      <CallTicket
        liveCallTicketIds={props.liveCallTicketIds}
        noteSubjects={props.noteSubjects}
        activeCallTicket={props.ticket}
      />
    );
  }
  return <Text>Unhandled Ticket</Text>;
};

export default ActiveTicket;

import { useQuery } from "@tanstack/react-query";
import useApi from "../../../shared/hooks/useApi";
import { queryKeys } from "../../../shared/query-keys";

export function usePatients() {
  const { api } = useApi();

  return useQuery({
    queryKey: queryKeys.patient.list(),
    queryFn: () => api.get("/agencies/:agencyId/patients", {}),
    staleTime: Infinity,
    select: (data) => data.patients,
  });
}

import { useQueryClient } from "@tanstack/react-query";
import { queryKeys } from "../query-keys";

export function useDownloadDocument() {
  const queryClient = useQueryClient();

  const download = async (url: string) => {
    const trimmedUrl = url.includes("s3.amazonaws.com") ? url.split("?")[0] : url;

    const blobUrl: string =
      queryClient.getQueryData(queryKeys.shared.image(trimmedUrl)) ??
      (await queryClient.fetchQuery({
        queryKey: queryKeys.shared.image(trimmedUrl),
        staleTime: Infinity,
        queryFn: async () => {
          const res = await fetch(url);
          const blob = await res.blob();
          return URL.createObjectURL(blob);
        },
      }));

    if (blobUrl) {
      const a = document.createElement("a");
      a.href = blobUrl;
      a.download = "doc.pdf";
      document.body.appendChild(a);
      a.click();
    }
  };

  return { download };
}

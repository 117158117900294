import { useQuery } from "@tanstack/react-query";
import { queryKeys } from "../query-keys";

const useCacheSignedUrlQuery = (url: string) => {
  const actualUrl = url.split("?")[0];

  const query = useQuery({
    queryKey: queryKeys.shared.image(actualUrl),
    queryFn: async () => {
      if (url.startsWith("blob:")) {
        return url;
      }

      const response = await fetch(url);
      const blob = await response.blob();
      return URL.createObjectURL(blob);
    },
    staleTime: Infinity,
  });
  return query;
};

export default useCacheSignedUrlQuery;

//! @ngInject
export function assignPatientCoordinatorModalCtrl($scope, $rootScope, $uibModalInstance, DatabaseApi, toaster, generalUtils) {
    /**
     * Params:
     * $scope.patients = [];
     * $scope.originCoordinatorId = (..id..);
     */
    $scope.patientsMap = DatabaseApi.patients();
    $scope.patientsList = $scope.patients.map(
      (patientId) => $scope.patientsMap[patientId]
    );
    $scope.chosenCoordinator = {};
    $scope.agencyMembers = DatabaseApi.getAgencyMembersArr();
    $scope.agencyMembers = $scope.agencyMembers.filter(
      (member) => member.id !== $scope.originCoordinatorId
    );

    $scope.filteredAgencyMembers = $scope.agencyMembers.filter((agencyMember) => {
      return agencyMember.officeIds.findIndex(agencyMemberOfficeId => $rootScope.user.agencyMember.officeIds.includes(agencyMemberOfficeId)) !== -1;
    }).sort(generalUtils.sortByDisplayName);

    $scope.agencyMembersNames = DatabaseApi.getAllAgencyMembersNames();

    $scope.agencyMembersSelectionSettings = {
      closeOnBlur: true,
      displayProp: "displayName",
      enableSearch: true,
      scrollable: true,
      scrollableHeight: "400px",
      showCheckAll: false,
      selectionLimit: 1,
      styleActive: true,
      smartButtonMaxItems: 3,
      smartButtonTextConverter: function (itemText) {
        return itemText;
      },
    };

    $scope.assign = function () {
      if ($scope.chosenCoordinator.id === undefined) {
        return;
      }
      const body = {
        originCoordinator: $scope.originCoordinatorId,
        newCoordinator: $scope.chosenCoordinator.id,
      };

      const url = `agencies/${$rootScope.agencyId}/coordinators/${$rootScope.agencyMemberId}/reassign_and_set_inactive`;
      DatabaseApi.post(url, body)
        .then(function () {
          toaster.pop("success", "Re-Assigned successfully");
          $uibModalInstance.close("ok");
        })
        .catch(function (err) {
          toaster.pop("warning", "something went wrong", "");
        });
    };

    $scope.closeModal = function () {
      $uibModalInstance.dismiss();
    };
  };

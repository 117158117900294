//! @ngInject
function sanitizeFilter($sanitize: any) {
  return function (input) {
    return $sanitize(input);
  };
}

sanitizeFilter.$name = "sanitize";

export default sanitizeFilter;

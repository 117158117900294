import { CloseIcon } from "@chakra-ui/icons";
import { Divider, IconButton } from "@chakra-ui/react";
import styled from "@emotion/styled";
import React from "react";
import { match } from "ts-pattern";
import { AttachmentMimeType, getPayloadTypeByMimeType } from "../utils/communication-utils";

interface Props {
  attachments: File[];
  onClickRemoveAttachment: (attachment: File) => void;
}

function ChatAttachmentsBar(props: Props) {
  return (
    <ChatAttachmentsBar.Root hidden={props.attachments.length === 0}>
      <Divider />
      <ChatAttachmentsBar.ContentWrapper>
        {props.attachments.map((attachment) => (
          <ChatAttachmentsBar.Content key={attachment.name}>
            <ChatAttachmentsBar.RemoveButton>
              <IconButton
                aria-label="remove-attachment"
                icon={<CloseIcon />}
                cursor="pointer"
                onClick={() => props.onClickRemoveAttachment(attachment)}
              ></IconButton>
            </ChatAttachmentsBar.RemoveButton>
            {match(getPayloadTypeByMimeType(attachment.type as AttachmentMimeType))
              .with("IMAGE", () => (
                <ChatAttachmentsBar.Image src={URL.createObjectURL(attachment)} />
              ))
              .with("VIDEO", () => (
                <ChatAttachmentsBar.Video src={URL.createObjectURL(attachment)} />
              ))
              .with("DOCUMENT", () => (
                <ChatAttachmentsBar.Document data={URL.createObjectURL(attachment)} />
              ))
              .exhaustive()}
          </ChatAttachmentsBar.Content>
        ))}
      </ChatAttachmentsBar.ContentWrapper>
    </ChatAttachmentsBar.Root>
  );
}

ChatAttachmentsBar.Root = styled.div``;

ChatAttachmentsBar.Content = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`;

ChatAttachmentsBar.ContentWrapper = styled.div`
  display: flex;
  align-items: flex-end;
  gap: 8px;
  overflow: auto;
  margin: 8px 8px 0;

  width: 100%;

  &[hidden] {
    margin: 0;
  }

  &:not([hidden]) + * {
    margin-top: 8px;
    border-top: 1px solid var(--gray-100);
  }
`;

ChatAttachmentsBar.RemoveButton = styled.div`
  --size: 22px;
  background-color: white;
  color: #888;
  border: none;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 6px;
  right: 6px;
  width: var(--size);
  height: var(--size);
  flex-shrink: 0;

  .IconButton {
    flex-shrink: 0;
    padding: 0;
  }
`;

ChatAttachmentsBar.Image = styled.img`
  width: 250px;
  height: 200px;
  border-radius: 16px;
`;

ChatAttachmentsBar.Document = styled.object`
  width: 250px;
  height: 200px;
  border-radius: 16px;
`;

ChatAttachmentsBar.Video = styled.embed`
  width: 250px !important;
  height: 200px !important;
  border-radius: 16px;
`;

export default ChatAttachmentsBar;

export const patientSurplusInvoiceList = {
  templateUrl: "admin/views/patient-surplus-invoices.html",
  bindings: {
    // items: "<",
    patientId: "<",
  },
  //! @ngInject
  controller: function (
    $scope,
    $rootScope,
    DatabaseApi,
    $uibModal,
    patientService,
    NgTableParams,
    toaster,    
    SweetAlert
  ) {
    

    const initTable = () => {
      const options = {
        count: 20,
        sorting: { id: "desc" },
      };

      $scope.patientSurplusInvoicesTable =this.patientSurplusInvoicesTable=new NgTableParams(options, {
        dataset: $scope.patientSurplusInvoices,
        counts:[]
      });
    };
    const initialize = async () => {
      $scope.patientId = this.patientId;
      $scope.patientSurplusInvoices = this.patientSurplusInvoices =  (
        await patientService.getPatientSurplusInvoices(this.patientId)
      ).patientSurplusInvoices;
      initTable();  
    };

    $scope.openNewPatientSurplusInvoiceModal = () => {
      var modalInstance = $uibModal.open({
        templateUrl: "admin/views/patient-surplus-invoice-create-modal.html",

        controller: "newPatientSurplusInvoiceModalCtrl",
        resolve: {
          patientId: function () {
            return $scope.patientId;
          },
        },
      });

      modalInstance.result.then(
        function (data) {
          const lines = JSON.parse(data).patientSurplusInvoiceCreateResponseRecords;
          console.log(lines);
          const text = lines.map(line => `${line.month} - ${line.result}`).join("\n")
          console.log(text)
          SweetAlert.swal({
            title: "Invoice create result",
            text: text,
            type: "info",
            showCloseButton: true,
            timer: 5000
          })
          initialize()
        },
        function () {
          toaster.pop("error", "Something went wrong");
        }
      );
    };
    $rootScope.$on('got_surplus_checks_data',() => {
      console.log('got_surplus_checks_data. refreshing invoices')
			initialize()
		});
    $scope.getPatientSurplusInvoicePdf = async (invoiceId) => {
       const data = await patientService.getPatientSurplusInvoicePdfUrl(this.patientId, invoiceId);
       window.open(data.url);
    };

    
    this.$onInit = () => {
      initialize();
    };
  },
};

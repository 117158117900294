import moment from "moment";
import _ from "lodash";

/* @ngInject */
export function VisitStatus() {
    this.getVisitStatus = (visit) => {

        if (visit.assignedAt || visit.assignedCaregiver || visit.splitShiftFullyAssigned === true) {
            return 'scheduled';
        }

        if (_.size(visit.pendingConfirmationRequests) > 0) {
            return "confirmation";
        }

        if(visit.acceptedBy && visit.acceptedBy.length){
            // Since we divide the visit rows into two parts (passed & future visit instances), there can be
            // caregivers that accepted the visit but not the old instances.
            // In this case we want the status to be 'unstaffed' and not 'waiting'
            if(visit.flexibleVisitParams === null){
              const requestedInstancesIds = [];
              visit.acceptedBy.forEach((accepted) => {
                if(accepted.requestedInstances !== null){
                    requestedInstancesIds.push(...accepted.requestedInstances)
                }
              });

              if(visit.visitInstances.some((instance) => requestedInstancesIds.includes(instance.id))){
                return 'waiting';
              }
            }
            else{
                return 'waiting';
            }
          }

        const now = new Date().getTime();
        const start = moment.tz(visit.startTime, visit.timezone).toDate().getTime();
        const end = moment.tz(visit.endTime, visit.timezone).toDate().getTime();

        if (visit.flexibleVisitParams !== null) {
            if (now > end) {
                return "unstaffed";
            }
        } else {
            if (now > start) {
                return "unstaffed";
            }
        }


        return 'pending';
    }
}

import { assertDefined, groupBy, upperCaseFirst } from "../../scripts/utils/generalUtils";

export const patientSurplusList = {
  templateUrl: "admin/views/patient-surpluses.html",
  bindings: {
    // items: "<",
    patientId: "<",
  },
  //! @ngInject
  controller: function (
    $scope,
    $rootScope,
    DatabaseApi,
    $filter,
    PatientModalService,
    patientService,
    NgTableParams,
    toaster,
    $uibModal,
    SweetAlert
  ) {
    // $scope.patientSurpluses = []
  
    const initPatientSurplusesTable = () => {
      const options = {
        count: 20,
        sorting: { id: "desc" },
      };

      $scope.patientSurplusesTable =this.patientSurplusesTable=new NgTableParams(options, {
        dataset: $scope.patientSurpluses,
        counts:[]
      });
    };
    const initialize = async () => {
      $scope.patientContracts =  PatientModalService.getPatientContractBasic(this.patientId, true).then(
        async (contracts) => {
          $scope.patientContracts = groupBy(contracts.map((contract) => ({
            ...contract,
            name: $filter("formatPatientContract")(contract),
          })),"id");
          console.log($scope.patientContracts)
          $scope.patientId = this.patientId;
          $scope.patientSurpluses = this.patientSurpluses =  (
            await patientService.getPatientSurpluses(this.patientId)
          ).patientSurpluses.map((i)=>(
          {
            ...i,
            patientContract: $scope.patientContracts.get(i.patientContractId)[0],
            
          }));
          initPatientSurplusesTable();
          console.log($scope.patientSurpluses);
        }
      );
     
    };

    $scope.openNewPatientSurplusModal = () => {
      var modalInstance = $uibModal.open({
        templateUrl: "admin/views/patient-surplus-create-modal.html",

        controller: "newPatientSurplusModalCtrl",
        resolve: {
          patientId: function () {
            return $scope.patientId;
          },
        },
      });

      modalInstance.result.then(
        function (data) {
          initialize()
        },
        function () {
          toaster.pop("error", "Something went wrong");
        }
      );
    };

    this.$onInit = () => {
      initialize();
    };
  },
};

import angular from "angular";
import { patientBpComponent } from "./components/patient-bp/patient-bp.component";
// import { FaxesService } from "./faxes.service";

export function registerPatientBpModule() {
    return (
      angular
        .module("dashboard")
  
        //  Services
  
        // Components
        .component(patientBpComponent.$name, patientBpComponent)
    );
  }
  
import React from "react";
import { ApiContext } from "../../core/api/api";

const useApi = () => {
  const api = React.useContext(ApiContext);

  return api;
};

export default useApi;

import angular from "angular";
import { CaregiverDirectDepositInfoComponent } from "./components/caregiver-direct-deposit-info/caregiver-direct-deposit-info.component";
import { CaregiverService } from "./services/caregiver.service";

export function registerCaregiverModule() {
  return angular
    .module("dashboard")
    
    .service(CaregiverService.$name, CaregiverService)
    
    .component(CaregiverDirectDepositInfoComponent.$name, CaregiverDirectDepositInfoComponent);
}

import PhoneNumber from "awesome-phonenumber";

export default {
  isValidNumber(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.isValid();
  },
  formatE164(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("e164");
  },
  formatNational(number: string) {
    const phoneNumber = new PhoneNumber(number, "US");
    return phoneNumber.getNumber("national");
  }
};

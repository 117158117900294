import React from "react";
import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import EntityCard from "./EntityCard";
import Dot02SIcon from "../icons/Dot02SIcon";
import { phoneFormatter } from "../utils/phone-formatter";
import { SearchEntity } from "../../modules/communication/components/DialerTabs";

const EntityPreview = ({
  entity,
  onClickDial,
}: {
  entity: SearchEntity;
  onClickDial: (val: string) => void;
}) => (
  <Menu>
    <MenuButton
      as={Box}
      cursor={"pointer"}
      borderBottom={"1px"}
      borderColor={"gray.100"}
      textAlign={"start"}
      pb={2}
    >
      <EntityCard
        entity={{
          ...entity,
          fullName: `${entity.firstName} ${entity.lastName}`,
        }}
      />
    </MenuButton>
    <MenuList>
      {entity.phoneNumbersExtended.map((phoneNumber) => (
        <MenuItem
          key={phoneNumber.phoneNumber}
          cursor={"pointer"}
          as={Flex}
          onClick={() => onClickDial(phoneNumber.phoneNumber)}
        >
          {phoneFormatter.formatNationalIfValid(phoneNumber.phoneNumber)}
          <Dot02SIcon />
          <Text>{phoneNumber.type}</Text>
        </MenuItem>
      ))}
      {entity.type === "Patient" &&
        entity.familyInfo.flatMap((familyMember) => [
          <MenuItem
            key={`${familyMember.firstName}-home`}
            cursor={"pointer"}
            as={Flex}
            onClick={() => onClickDial(familyMember.homePhoneNumber)}
          >
            {phoneFormatter.formatNationalIfValid(familyMember.homePhoneNumber)}
            <Dot02SIcon />
            <Text>
              {familyMember.firstName} {familyMember.lastName}
            </Text>
            <Dot02SIcon />
            <Text>Home</Text>
          </MenuItem>,
          <MenuItem
            key={`${familyMember.firstName}-mobile`}
            cursor={"pointer"}
            as={Flex}
            onClick={() => onClickDial(familyMember.mobilePhoneNumber)}
          >
            {phoneFormatter.formatNationalIfValid(familyMember.mobilePhoneNumber)}
            <Dot02SIcon />
            <Text>
              {familyMember.firstName} {familyMember.lastName}
            </Text>
            <Dot02SIcon />
            <Text>Mobile</Text>
          </MenuItem>,
        ])}
    </MenuList>
  </Menu>
);

export default EntityPreview;

import useAuth from "./useAuth";

const useAuthData = () => {
  const { authData } = useAuth();

  if (authData.type !== "authenticated") {
    throw new Error("Cannot get auth data when not logged in");
  }

  return authData.data;
};

export default useAuthData;

//! @ngInject
class ReleaseNotesAddOnCtrl implements ng.IComponentController {

    constructor(
      private $rootScope: angular.IRootScopeService
    ) {}

    async $onInit() {
        if(this.$rootScope.isOnboardingAgency) {
          const s :any = document.createElement('script'); s.type = "text/javascript"; s.async = true;
          s.src = "https://arn.upraise.io/rest/arn/latest/widget/widget-init?widgetId=a52f119a-2bf7-42c9-ad82-490a7c5dd4d8&clientKey=7a16d222-b42c-3ef3-9201-e437e762d591";
          s.onload = s.onreadystatechange = function() {
              new (window as any).arnWidget();
          };
          const h = document.getElementsByTagName('head')[0];
          h.appendChild(s);
        }
     }
}

type ReleaseNotesAddOnComponentOptions = angular.IComponentOptions
export const ReleaseNotesAddOn: ReleaseNotesAddOnComponentOptions = {
  controller: ReleaseNotesAddOnCtrl
};

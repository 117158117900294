import { Box, Flex, FormControl, FormLabel, Input, List, ListItem } from "@chakra-ui/react";
import { Instant, LocalDate, LocalDateTime } from "@js-joda/core";
import React from "react";
import { Messages, ResponseOf } from "../../../core/api";
import { dateFormatter } from "../../../shared/utils/date-formatter";
import IntakePatientProfileDatepicker from "./IntakePatientProfileDatepicker";
import IntakePatientProfileDropdown from "./IntakePatientProfileDropdown";
import IntakePatientProfileInput from "./IntakePatientProfileInput";

const patientGenderOptions = [
  {
    value: "M",
    label: "Male",
  },
  {
    value: "F",
    label: "Female",
  },
];

const EligibilityFlowTypes = [
  "Straight medicaid to MLTC",
  "MLTC to MLTC transfer",
  "No medicaid",
  "Not eligible - Restriction code",
  "Staying on MMC",
  "Unknown",
] as const;

interface Props {
  isEditMode: boolean;
  intakeStatuses: ResponseOf<"get", "./patient_intake/intake_status">["intakeStatuses"];
  patientInfo: {
    dateOfBirth: LocalDate | null;
    lastCallDate: LocalDateTime | null;
    nextCallDate: LocalDateTime | null;
    gender: "M" | "F" | null;
    address: string | null;
    address2: string | null;
    addressInstructions: string | null;
    addressComponents: Messages["IntakePatientProfile"]["addressComponents"] | null;
    createdAt: Instant;
    intakeStatus: string | null;
    eligibilityFlow: string | null;
  };
  places: google.maps.places.AutocompletePrediction[];
  showPredictions: boolean;
  onChangePatientInfoField: (
    field:
      | "lastCallDate"
      | "nextCallDate"
      | "dateOfBirth"
      | "gender"
      | "address2"
      | "addressInstructions"
      | "createdAt"
      | "intakeStatus"
      | "eligibilityFlow",
    value:
      | string
      | LocalDate
      | LocalDateTime
      | Messages["IntakePatientProfile"]["addressComponents"]
      | null
  ) => void;
  onChangeAddress: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onClickAddress: (prediction: google.maps.places.AutocompletePrediction) => void;
}

const IntakeProfilePersonalInfoSection = (props: Props) => {
  return (
    <Flex direction="column" gap={4}>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          label={"DOB"}
          value={props.patientInfo.dateOfBirth}
          pickerYearsRange={[1910, LocalDate.now().year()]}
          onChange={(value) => props.onChangePatientInfoField("dateOfBirth", value)}
        />
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          withTime={true}
          label={"Last Call"}
          value={props.patientInfo.lastCallDate}
          onChange={(value) => props.onChangePatientInfoField("lastCallDate", value)}
        />
        <IntakePatientProfileDatepicker
          isEditable={props.isEditMode}
          isRequired={false}
          withTime={true}
          label={"Next Call"}
          value={props.patientInfo.nextCallDate}
          onChange={(value) => props.onChangePatientInfoField("nextCallDate", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          label="Gender"
          isEditable={props.isEditMode}
          isRequired={true}
          options={patientGenderOptions}
          currValue={{
            value: props.patientInfo.gender,
            label: props.patientInfo.gender === "F" ? "Female" : "Male" ?? "Not chosen",
          }}
          onChange={(value) => props.onChangePatientInfoField("gender", value)}
        />
        <FormControl>
          <FormLabel> Created Date </FormLabel>
          <Input isDisabled={true} value={dateFormatter.toDate(props.patientInfo.createdAt)} />
        </FormControl>
      </Flex>
      <Flex direction="row">
        <FormControl>
          <FormLabel>Address</FormLabel>
          <Input
            isDisabled={!props.isEditMode}
            value={props.patientInfo.address ?? ""}
            autoComplete="new-pasword"
            onChange={props.onChangeAddress}
          />
          {props.showPredictions ? (
            <StylePredisctionList
              placePredictions={props.places}
              onClickAddressPrediction={props.onClickAddress}
            />
          ) : null}
        </FormControl>
      </Flex>
      <Flex direction="row">
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          label="Address Line 2"
          value={props.patientInfo.address2 ?? ""}
          isRequired={false}
          onChange={(value) => props.onChangePatientInfoField("address2", value)}
        />
      </Flex>
      <Flex direction="row">
        <IntakePatientProfileInput
          isEditable={props.isEditMode}
          label="Address Instructions"
          value={props.patientInfo.addressInstructions ?? ""}
          isRequired={false}
          onChange={(value) => props.onChangePatientInfoField("addressInstructions", value)}
        />
      </Flex>
      <Flex direction="row" gap={4}>
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          label="Eligibility Flow"
          currValue={{
            value: props.patientInfo.eligibilityFlow,
            label: props.patientInfo.eligibilityFlow ?? "Not Chosen",
          }}
          isRequired={true}
          options={EligibilityFlowTypes.map((option) => ({
            value: option,
            label: option,
          }))}
          onChange={(value) => props.onChangePatientInfoField("eligibilityFlow", value)}
        />
        <IntakePatientProfileDropdown
          isEditable={props.isEditMode}
          label="Intake Status"
          currValue={{
            value: props.patientInfo.intakeStatus,
            label: props.patientInfo.intakeStatus ?? "Not Chosen",
          }}
          isRequired={true}
          options={props.intakeStatuses.map((value) => ({
            value: value,
            label: value,
          }))}
          onChange={(value) => props.onChangePatientInfoField("intakeStatus", value)}
        />
      </Flex>
    </Flex>
  );
};

export default IntakeProfilePersonalInfoSection;

const StylePredisctionList = (props: {
  placePredictions: google.maps.places.AutocompletePrediction[];
  onClickAddressPrediction: (prediction: google.maps.places.AutocompletePrediction) => void;
}) => (
  <Box
    padding={3}
    border="1px"
    borderColor={"chakra-border-color"}
    position={"fixed"}
    bg="white"
    zIndex={1}
    width="xl"
  >
    <List display="flex" gap={4} flexDirection="column">
      {props.placePredictions.map((prediction) => (
        <ListItem
          borderBottom={"1px"}
          borderColor={"chakra-border-color"}
          key={prediction.place_id}
          onClick={() => props.onClickAddressPrediction(prediction)}
          cursor={"pointer"}
        >
          {prediction.description}
        </ListItem>
      ))}
    </List>
  </Box>
);
